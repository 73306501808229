import { useNavigate, useParams } from "react-router-dom";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ACTION_TYPE, REPORT_NAME, REPORT_PATH } from "../../constant/reportType";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Switch, Table } from "antd";
import { AUDIT_COLUMN, AUDIT_FRAMEWORK_COLUMN, CERTIFICATE_TYPE, DIMENSION_MASTER_COLUMN, GROUP_MASTER_COLUMN, ORGANIZATION_MASTER_COLUMN, PROCESS_MASTER_COLUMN, USER_COLUMN } from "../../constant/AppConstant";
import { BsDownload, BsFileArrowDown, BsFunnel, BsPencilSquare, BsPlus } from "react-icons/bs";
import { useDeleteDimensionMasterMutation, useGetAllDimensionMasterMutation, useGetAllDimensionMasterWithSearchMutation } from "../../redux-toolkit/services/dimensionMasterService";
import { useDeleteProcessMasterMutation, useGetAllProcessMasterMutation, useGetAllProcessMasterWithSearchMutation } from "../../redux-toolkit/services/processMasterService";
import { getAllDimensionMasterDetails, getDimensionMasterTotalRecords, setDimensionMaster } from "../../redux-toolkit/slices/dimensionMasterSlice";
import { getAllProcessMasterDetails, getProcessMasterTotalRecords, setProcessMaster } from "../../redux-toolkit/slices/processMasterSlice";
import { alertMessage } from "../../utils/AlertService";
import MasterReportData from "./MasterReportData";
import { useAppSelector } from "../../redux-toolkit/hooks";
import Loading from "../Loading";
import { useDeleteAuditFrameworkMutation, useGetAllAuditFrameworkMutation, useGetAuditFrameworkBySearchMutation } from "../../redux-toolkit/services/auditFrameworkService";
import { useDeleteAuditMutation, useGetAllAuditsMutation, useGetAuditBySearchMutation } from "../../redux-toolkit/services/auditService";
import { getAllAuditFrameworkDetails, getAuditFrameworkTotalRecords, setAuditFramework } from "../../redux-toolkit/slices/auditFrameworkSlice";
import { getAllAuditDetails, getAuditTotalRecords, setAudit } from "../../redux-toolkit/slices/auditSlice";
import { useDeleteGroupMasterMutation, useGetAllGroupMasterMutation, useGetAllGroupMasterWithSearchMutation } from "../../redux-toolkit/services/groupMasterService";
import { getAllGroupMasterDetails, getGroupMasterTotalRecords, setGroupMaster } from "../../redux-toolkit/slices/groupMasterSlice";
import { useGenerateCertificateMutation, useGeneratePdfMutation } from "../../redux-toolkit/services/pdfService";
import { useDeleteOrganizationMasterMutation, useFindAllOrganizationMasterMutation, useGetAllOrganizationMasterMutation } from "../../redux-toolkit/services/organizationMasterService";
import { allOrganizationMasterDetails, getAllOrganizationMasterDetails, getOrganizationMasterTotalRecords, setAllOrganizationMaster, setOrganizationMaster } from "../../redux-toolkit/slices/organizationMasterSlice";
import { useDeleteUserByIdMutation, useGetAllUserMutation } from "../../redux-toolkit/services/allUserService";
import { getAllUsersWithPaginationDetails, setAllUserWithPagination } from "../../redux-toolkit/slices/allUserSlice";
import { Form, Formik } from "formik";
import { Excel } from "antd-table-saveas-excel";
import AuditPieChart from "../AuditPieChart";
import AuditStatusChart from "../AuditStatusChart";
import FilterModal from "../FilterModal";

type Props = {};
const AllMasterReports = (props: Props) => {

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const localUserObj: any = getLocalStorageData("localUser");

    //state
    const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
    const [reportName, setReportName]: any = useState();
    const [currentpage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [show, setShow] = useState(false);
    const [filterShow, setShowFilter] = useState(false);
    const [selectedRow, setSelectedRow]: any = useState([]);
    const [columData, setColumnData]: any = useState();
    const [dataSource, setDatasource]: any = useState([]);
    const [call, setCall] = useState("N");
    const [showDownloadOption, setShowDownloadOption] = useState(false);

    //getMutations
    const [getAllDimensionMaster, dimensionMasterResponse] = useGetAllDimensionMasterMutation();
    const [getAllProcessMaster, processMasterResponse] = useGetAllProcessMasterMutation();
    const [getAllAuditFramework, auditFrameworkResponse] = useGetAllAuditFrameworkMutation();
    const [getAllAudit, auditResponse] = useGetAllAuditsMutation();
    const [getAllGroupMaster, groupMasterResponse] = useGetAllGroupMasterMutation();
    const [getAllOrganizationMaster, allOrganizationMasterResponse] = useFindAllOrganizationMasterMutation();
    const [getAllUser, getAllUserResponse] = useGetAllUserMutation();


    const [generatePdf, generatePdfResponse] = useGeneratePdfMutation();
    const [generateCertificate, generateCertificateResponse] = useGenerateCertificateMutation();

    //get by search
    const [getAllDimensionMasterWithSearch, dimensionMasterWithSearchResponse] = useGetAllDimensionMasterWithSearchMutation();
    const [getAllProcessMasterWithSearch, processMasterWithSearchResponse] = useGetAllProcessMasterWithSearchMutation();
    const [getAuditFrameworkBySearch, auditFrameworkBySearchResponse] = useGetAuditFrameworkBySearchMutation();
    const [getAuditBySearch, auditBySearchResponse] = useGetAuditBySearchMutation();
    const [getGroupMasterBySearch, groupMasterBySearchResponse] = useGetAllGroupMasterWithSearchMutation();

    //delete mutation
    const [deleteDimensionMaster, deleteDimensionMasterResponse] = useDeleteDimensionMasterMutation();
    const [deleteProcessMaster, deleteProcessMasterResponse] = useDeleteProcessMasterMutation();
    const [deleteAuditFramework, deleteAuditFrameworkResponse] = useDeleteAuditFrameworkMutation();
    const [deleteAudit, deleteAuditResponse] = useDeleteAuditMutation();
    const [deleteGroupMaster, deleteGroupMasterResponse] = useDeleteGroupMasterMutation();
    // const [deleteOrganizationMaster, deleteOrganizationMasterResponse] = useDeleteOrganizationMasterMutation();
    const [deleteUser, deleteUserResponse] = useDeleteUserByIdMutation();

    //totalRecords
    const totalDimensionMaster: any = useAppSelector(getDimensionMasterTotalRecords);
    const totalProcessMaster: any = useAppSelector(getProcessMasterTotalRecords);
    const totalAuditFramework: any = useAppSelector(getAuditFrameworkTotalRecords);
    const totalAudit: any = useAppSelector(getAuditTotalRecords);
    const totalGroupMaster: any = useAppSelector(getGroupMasterTotalRecords);
    // const totalOrganizationMaster: any = useAppSelector(getOrganizationMasterTotalRecords);

    //selector
    const dimensionMasterData: any = useAppSelector(getAllDimensionMasterDetails);
    const processMasterData: any = useAppSelector(getAllProcessMasterDetails);
    const auditFrameworkData: any = useAppSelector(getAllAuditFrameworkDetails);
    const auditData: any = useAppSelector(getAllAuditDetails);
    const groupMasterData: any = useAppSelector(getAllGroupMasterDetails);
    const organizationMasterData: any = useAppSelector(allOrganizationMasterDetails);
    const allUser: any = useAppSelector(getAllUsersWithPaginationDetails);


    useEffect(() => {
        switch (params.reportType) {
            case REPORT_PATH.DIMENSION_MASTER:
                setReportName(REPORT_NAME.DIMENSION_MASTER)
                break;
            case REPORT_PATH.PROCESS_MASTER:
                setReportName(REPORT_NAME.PROCESS_MASTER)
                break;
            case REPORT_PATH.AUDIT_FRAMEWORK:
                setReportName(REPORT_NAME.AUDIT_FRAMEWORK)
                break;
            case REPORT_PATH.AUDIT:
                setReportName(REPORT_NAME.AUDIT)
                break;
            case REPORT_PATH.GROUP_MASTER:
                setReportName(REPORT_NAME.GROUP_MASTER)
                break;
            // case REPORT_PATH.ORGANIZATION_MASTER:
            //     setReportName(REPORT_NAME.ORGANIZATION_MASTER)
            //     break;
            case REPORT_PATH.USER_MASTER:
                setReportName(REPORT_NAME.USER_MASTER)
                break;
            default:
        }
    }, [params.reportType]);

    useEffect(() => {
        const temp: any = [];
        const tempAction: any = {
            title: "Action",
            dataIndex: "action",
            key: "name",
            fixed: "right",
            width: 120,
            render: (index: any, record: any) => {
                return (
                    // for delete switch button
                    <div className="d-flex justify-content-between">
                        <div className="px-2 pe-auto">
                            <Switch
                                className={`${record.status === true ||
                                    record.enabled === true ||
                                    record.isEnabled === true ? "bg-danger" : "bg-secondry"}`}
                                checked={
                                    record.status === true ||
                                        record.enabled === true ||
                                        record.isEnabled === true
                                        ? true
                                        : false
                                }
                                onClick={() => {
                                    onDeleteData(record);
                                }}
                            />
                        </div>
                        {record.status === true ||
                            record.enabled === true ||
                            record.isEnabled === true ? (
                            <div
                                className="px-2 pe-auto"
                                onClick={() => {
                                    handleOpenForm(record);
                                }}
                            >
                                <BsPencilSquare size={18} />
                            </div>
                        ) : null}
                        {/* {record.status === true ? ( */}
                        <div
                            className="px-2 pe-auto text-danger"
                            onClick={() => {
                                setSelectedRow(record);
                                setShowDownloadOption(true);
                            }}
                        >
                            <BsFileArrowDown size={20} />
                        </div>
                        {/* ) : null} */}
                    </div>
                );
            },
        };

        if (reportName === REPORT_NAME.DIMENSION_MASTER && DIMENSION_MASTER_COLUMN) {
            DIMENSION_MASTER_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        } else if (reportName === REPORT_NAME.PROCESS_MASTER && PROCESS_MASTER_COLUMN) {
            PROCESS_MASTER_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        } else if (reportName === REPORT_NAME.AUDIT_FRAMEWORK && AUDIT_FRAMEWORK_COLUMN) {
            AUDIT_FRAMEWORK_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        } else if (reportName === REPORT_NAME.AUDIT && AUDIT_COLUMN) {
            AUDIT_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        } else if (reportName === REPORT_NAME.GROUP_MASTER && GROUP_MASTER_COLUMN) {
            GROUP_MASTER_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        }
        // else if (reportName === REPORT_NAME.ORGANIZATION_MASTER && ORGANIZATION_MASTER_COLUMN) {
        //     ORGANIZATION_MASTER_COLUMN.map((item: any) => {
        //         temp.push(item);
        //     });
        //     temp.push(tempAction);
        //     setColumnData(temp);
        // } 
        else if (reportName === REPORT_NAME.USER_MASTER && USER_COLUMN) {
            USER_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        }
    }, [reportName, params.reportType]);

    const handleSubmitForm = (values: any) => {
        if (values.type) {
            generateCertificate({
                token: localUserObj.token,
                type: values.type,
                auditId: parseInt(selectedRow.id)
            })
        } else {
            alertMessage("Please select type of certificate", "error");
        }
    }

    useEffect(() => {
        if (reportName === REPORT_NAME.DIMENSION_MASTER) {
            // setReportName(REPORT_NAME.DIMENSION_MASTER);
            getAllDimensionMaster({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.PROCESS_MASTER) {
            // setReportName(REPORT_NAME.PROCESS_MASTER);
            getAllProcessMaster({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
            // setReportName(REPORT_NAME.AUDIT_FRAMEWORK);
            getAllAuditFramework({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.AUDIT) {
            // setReportName(REPORT_NAME.AUDIT);
            getAllOrganizationMaster({ token: localUserObj.token })
            getAllAudit({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.GROUP_MASTER) {
            // setReportName(REPORT_NAME.GROUP_MASTER);
            getAllGroupMaster({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        }
        // else if (reportName === REPORT_NAME.ORGANIZATION_MASTER) {
        //     getAllOrganizationMaster({
        //         token: localUserObj.token,
        //         payload: {
        //             size: pageSize,
        //             page: currentpage - 1,
        //             orderBy: "asc"
        //         }
        //     });
        // } 
        else if (reportName === REPORT_NAME.USER_MASTER) {
            getAllUser({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        }
    }, [reportName]);

    useEffect(() => {
        if (call === "Y") {
            if (reportName === REPORT_NAME.DIMENSION_MASTER) {
                // setReportName(REPORT_NAME.DIMENSION_MASTER);
                getAllDimensionMaster({
                    token: localUserObj.token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            } else if (reportName === REPORT_NAME.PROCESS_MASTER) {
                // setReportName(REPORT_NAME.PROCESS_MASTER);
                getAllProcessMaster({
                    token: localUserObj.token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            } else if (reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
                // setReportName(REPORT_NAME.AUDIT_FRAMEWORK);
                getAllAuditFramework({
                    token: localUserObj.token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            } else if (reportName === REPORT_NAME.AUDIT) {
                // setReportName(REPORT_NAME.AUDIT);
                getAllAudit({
                    token: localUserObj.token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            } else if (reportName === REPORT_NAME.GROUP_MASTER) {
                // setReportName(REPORT_NAME.GROUP_MASTER);
                getAllGroupMaster({
                    token: localUserObj.token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            }
            // else if (reportName === REPORT_NAME.ORGANIZATION_MASTER) {
            //     getAllOrganizationMaster({
            //         token: localUserObj.token,
            //         payload: {
            //             size: pageSize,
            //             page: currentpage - 1,
            //             orderBy: "asc"
            //         }
            //     });
            //     setCall("N");
            // } 
            else if (reportName === REPORT_NAME.USER_MASTER) {
                getAllUser({
                    token: localUserObj.token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            }
        }
    }, [call]);

    const paginationChange = (page: number, pageSize: number) => {
        if (reportName === REPORT_NAME.DIMENSION_MASTER) {
            getAllDimensionMaster({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.PROCESS_MASTER) {
            getAllProcessMaster({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
            getAllAuditFramework({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.AUDIT) {
            getAllAudit({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.GROUP_MASTER) {
            getAllGroupMaster({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        }
        // else if (reportName === REPORT_NAME.ORGANIZATION_MASTER) {
        //     getAllOrganizationMaster({
        //         token: localUserObj.token,
        //         payload: {
        //             size: pageSize,
        //             page: page - 1,
        //             orderBy: "asc"
        //         }
        //     });
        // } 
        else if (reportName === REPORT_NAME.USER_MASTER) {
            getAllUser({
                token: localUserObj.token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        }
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const handleShow = () => {
        setActionType(ACTION_TYPE.ADD_ACTION);
        setShow(true)
    };

    const handleFilterShow = () => {
        setShowFilter(true)
    };

    const handleExportClick = () => {
        let columns: any;
        let dataSource1: any;
        let fileName: any;
        if (reportName === REPORT_NAME.AUDIT) {
            fileName = REPORT_NAME.AUDIT;
            columns = AUDIT_COLUMN;
            dataSource1 = dataSource;
        } else if (reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
            fileName = REPORT_NAME.AUDIT_FRAMEWORK;
            columns = AUDIT_FRAMEWORK_COLUMN;
            dataSource1 = dataSource;
        }

        console.log(columns)
        console.log(dataSource1)
        const excel = new Excel();
        excel
            .addSheet("test")
            .addColumns(columns)
            .addDataSource(dataSource1, {
                str2Percent: true
            })
            .saveAs(`${fileName}.xlsx`);
    };

    //delete..........
    const onDeleteData = (record: any) => {
        if (reportName === REPORT_NAME.DIMENSION_MASTER) {
            deleteDimensionMaster({
                id: record.id,
                token: localUserObj.token
            })
        } else if (reportName === REPORT_NAME.PROCESS_MASTER) {
            deleteProcessMaster({
                id: record.id,
                token: localUserObj.token
            })
        } else if (reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
            deleteAuditFramework({
                id: record.id,
                token: localUserObj.token
            })
        } else if (reportName === REPORT_NAME.AUDIT) {
            deleteAudit({
                id: record.id,
                token: localUserObj.token
            })
        } else if (reportName === REPORT_NAME.PROCESS_MASTER) {
            deleteGroupMaster({
                id: record.id,
                token: localUserObj.token
            })
        } else if (reportName === REPORT_NAME.GROUP_MASTER) {
            deleteGroupMaster({
                id: record.id,
                token: localUserObj.token
            })
        }
        // else if (reportName === REPORT_NAME.ORGANIZATION_MASTER) {
        //     deleteOrganizationMaster({
        //         id: record.id,
        //         token: localUserObj.token
        //     })
        // } 
        else if (reportName === REPORT_NAME.USER_MASTER) {
            deleteUser({
                id: record.id,
                token: localUserObj.token
            })
        }
    };

    const handleOpenForm = (record: any) => {
        setActionType(ACTION_TYPE.UPDATE_ACTION);
        setSelectedRow(record);
        setShow(true);
    };

    //get allDimensionMaster.......
    useEffect(() => {
        if (dimensionMasterResponse.isSuccess) {
            dispatch(setDimensionMaster(dimensionMasterResponse));
        } else if (dimensionMasterResponse.isError) {
            alertMessage("Error while fetching dimension master", "error");
            setDatasource([]);
        }
    }, [dimensionMasterResponse.isSuccess, dimensionMasterResponse.isError]);

    //get allProcessMaster.......
    useEffect(() => {
        if (processMasterResponse.isSuccess) {
            dispatch(setProcessMaster(processMasterResponse));
        } else if (processMasterResponse.isError) {
            alertMessage("Error while fetching process master", "error");
            setDatasource([]);
        }
    }, [processMasterResponse.isSuccess, processMasterResponse.isError]);

    //get allAuditFramework.......
    useEffect(() => {
        if (auditFrameworkResponse.isSuccess) {
            dispatch(setAuditFramework(auditFrameworkResponse));
        } else if (auditFrameworkResponse.isError) {
            alertMessage("Error while fetching audit framework", "error");
            setDatasource([]);
        }
    }, [auditFrameworkResponse.isSuccess, auditFrameworkResponse.isError]);

    //get allaudit.......
    useEffect(() => {
        if (auditResponse.isSuccess) {
            dispatch(setAudit(auditResponse));
        } else if (auditResponse.isError) {
            alertMessage("Error while fetching audit", "error");
            setDatasource([]);
        }
    }, [auditResponse.isSuccess, auditResponse.isError]);

    //get allGroupMaster.......
    useEffect(() => {
        if (groupMasterResponse.isSuccess) {
            dispatch(setGroupMaster(groupMasterResponse));
        } else if (groupMasterResponse.isError) {
            alertMessage("Error while fetching group master", "error");
            setDatasource([]);
        }
    }, [groupMasterResponse.isSuccess, groupMasterResponse.isError]);

    useEffect(() => {
        if (getAllUserResponse.isSuccess) {
            dispatch(setAllUserWithPagination(getAllUserResponse));
        } else if (getAllUserResponse.isError) {
            alertMessage("Error while fetching users", "error");
            setDatasource([]);
        }
    }, [getAllUserResponse.isSuccess, getAllUserResponse.isError]);

    //data

    useEffect(() => {
        if (dimensionMasterData && reportName === REPORT_NAME.DIMENSION_MASTER) {
            setDatasource(dimensionMasterData);
        }
    }, [dimensionMasterData]);

    useEffect(() => {
        if (processMasterData && reportName === REPORT_NAME.PROCESS_MASTER) {
            setDatasource(processMasterData);
        }
    }, [processMasterData]);

    useEffect(() => {
        if (auditFrameworkData && reportName === REPORT_NAME.AUDIT_FRAMEWORK) {
            setDatasource(auditFrameworkData);
        }
    }, [auditFrameworkData]);

    useEffect(() => {
        if (auditData && reportName === REPORT_NAME.AUDIT) {
            setDatasource(auditData);
        }
    }, [auditData]);

    useEffect(() => {
        if (groupMasterData && reportName === REPORT_NAME.GROUP_MASTER) {
            setDatasource(groupMasterData);
        }
    }, [groupMasterData]);

    // useEffect(() => {
    //     if (organizationMasterData && reportName === REPORT_NAME.ORGANIZATION_MASTER) {
    //         setDatasource(organizationMasterData);
    //     }
    // }, [organizationMasterData]);

    useEffect(() => {
        if (allUser) {
            buildUserData(allUser);
        }
    }, [allUser]);

    const buildUserData = (data: any) => {
        let temp: any = [];
        data.forEach((element: any) => {
            // let payload =
            temp.push({
                id: element.id,
                name:
                    (element.firstName === null ? "" : element.firstName) +
                    " " +
                    (element.lastName === null ? "" : element.lastName),
                phoneNumber: (element.phoneNumber === null || element.phoneNumber === "") ? "-" : element.phoneNumber,
                email: element.email === null ? "-" : element.email,
                userRole: element.userRole.name === null ? "-" : element.userRole.name,
                userRoleId: element.userRole.id === null ? "-" : element.userRole.id,
                userGroup:
                    element.userGroup.name === null ? "-" : element.userGroup.name,
                userGroupId: element.userGroup.id === null ? "-" : element.userGroup.id,
                countryId: element.countryId === null ? "-" : element.countryId,
                stateId: element.stateId === null ? "-" : element.stateId,
                enabled: element.enabled,
            });
        });
        setDatasource(temp);
    };

    //deleteResponse responses

    useEffect(() => {
        if (deleteDimensionMasterResponse.isSuccess) {
            alertMessage("Dimension master updated successfully", "success");
            setCall("Y");
        } else if (deleteDimensionMasterResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteDimensionMasterResponse.isSuccess, deleteDimensionMasterResponse.isError]);

    useEffect(() => {
        if (deleteProcessMasterResponse.isSuccess) {
            alertMessage("Process master updated successfully", "success");
            setCall("Y");
        } else if (deleteProcessMasterResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteProcessMasterResponse.isSuccess, deleteProcessMasterResponse.isError]);

    useEffect(() => {
        if (deleteAuditFrameworkResponse.isSuccess) {
            alertMessage("Audit framework updated successfully", "success");
            setCall("Y");
        } else if (deleteAuditFrameworkResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteAuditFrameworkResponse.isSuccess, deleteAuditFrameworkResponse.isError]);

    useEffect(() => {
        if (deleteAuditResponse.isSuccess) {
            alertMessage("Audit updated successfully", "success");
            setCall("Y");
        } else if (deleteAuditResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteAuditResponse.isSuccess, deleteAuditResponse.isError]);

    useEffect(() => {
        if (deleteGroupMasterResponse.isSuccess) {
            alertMessage("Group master updated successfully", "success");
            setCall("Y");
        } else if (deleteGroupMasterResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteGroupMasterResponse.isSuccess, deleteGroupMasterResponse.isError]);

    useEffect(() => {
        if (deleteUserResponse.isSuccess) {
            alertMessage("User status updated successfully", "success");
            setCall("Y");
        } else if (deleteUserResponse.isError) {
            let response: any = deleteUserResponse.error;
            alertMessage(response, "error");
        }
    }, [deleteUserResponse.isSuccess, deleteUserResponse.isError]);

    useEffect(() => {
        if (generateCertificateResponse.isSuccess) {
            alertMessage("Certificate generated successfully", "success");
            setShowDownloadOption(false);
        } else if (generateCertificateResponse.isError) {
            alertMessage("Error while generating certificate", "error");
        }
    }, [generateCertificateResponse.isSuccess, generateCertificateResponse.isError]);

    useEffect(() => {
        if (allOrganizationMasterResponse.isSuccess) {
            dispatch(setAllOrganizationMaster(allOrganizationMasterResponse));
        } else if (allOrganizationMasterResponse.isError) {
            alertMessage("Error fetching organizations", "error");
        }
    }, [allOrganizationMasterResponse.isSuccess, allOrganizationMasterResponse.isError]);

    const handleClose = () => {
        setShowDownloadOption(false);
    }

    return (
        <div className="mt-2 overflow-y-auto">
            <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
                <div className="d-flex align-items-center w-100 px-2">
                    <h5
                        className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
                        style={{ fontWeight: "700" }}
                    >
                        {reportName}
                    </h5>
                </div>
                <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
                    <div className="d-flex px-2 align-items-center mt-1">
                        {(reportName === REPORT_NAME.AUDIT) && (
                            <Button
                                variant="danger"
                                className="d-flex align-items-center me-2"
                                onClick={handleFilterShow}
                            >
                                <BsFunnel size={16} className="me-2" />
                                Filter
                            </Button>
                        )}
                        {(reportName === REPORT_NAME.AUDIT || reportName === REPORT_NAME.AUDIT_FRAMEWORK) && (
                            <Button
                                variant="danger"
                                className="d-flex align-items-center me-2"
                                onClick={handleExportClick}
                            >
                                <BsDownload size={16} className="me-2" />
                                Export
                            </Button>
                        )}
                        <Button
                            variant="danger"
                            className="d-flex align-items-center me-0 text-nowrap"
                            onClick={handleShow}
                        // onClick={() => {
                        //     setActionType(ACTION_TYPE.ADD_ACTION);
                        // }}
                        >
                            <BsPlus />
                            Add
                        </Button>
                    </div>
                    {dimensionMasterResponse.isLoading ||
                        processMasterResponse.isLoading ||
                        auditFrameworkResponse.isLoading ||
                        auditResponse.isLoading ||
                        groupMasterResponse.isLoading ||
                        dimensionMasterWithSearchResponse.isLoading ||
                        processMasterWithSearchResponse.isLoading ||
                        auditFrameworkBySearchResponse.isLoading ||
                        auditBySearchResponse.isLoading ||
                        groupMasterBySearchResponse.isLoading ||
                        deleteDimensionMasterResponse.isLoading ||
                        deleteProcessMasterResponse.isLoading ||
                        deleteAuditFrameworkResponse.isLoading ||
                        deleteAuditResponse.isLoading ||
                        deleteGroupMasterResponse.isLoading ||
                        generateCertificateResponse.isLoading ? (
                        <Loading />
                    ) : (
                        ""
                    )}

                    {show && (
                        <MasterReportData
                            show={show}
                            setShow={setShow}
                            actionType={actionType}
                            reportName={reportName}
                            data={selectedRow}
                            setCall={setCall}
                        />
                    )}
                </div>
            </div>
            <div className="mt-3 my-2 w-100 overflow-x-auto rounded-3">
                <Table
                    bordered={true}
                    rowKey={"id"}
                    scroll={{ x: 'max-content' }}
                    tableLayout="fixed"
                    pagination={{
                        total: (() => {
                            switch (reportName) {
                                case REPORT_NAME.DIMENSION_MASTER:
                                    return totalDimensionMaster;
                                case REPORT_NAME.PROCESS_MASTER:
                                    return totalProcessMaster;
                                case REPORT_NAME.AUDIT_FRAMEWORK:
                                    return totalAuditFramework;
                                case REPORT_NAME.AUDIT:
                                    return totalAudit;
                                case REPORT_NAME.GROUP_MASTER:
                                    return totalGroupMaster;
                                default:
                                    return dataSource.length;
                            }
                        })(),
                        // hideOnSinglePage: true,
                        responsive: true,
                        current: currentpage,
                        // showSizeChanger: true,  
                        pageSizeOptions: [10, 20, 30],
                        onChange: (page, pageSize) => {
                            paginationChange(page, pageSize);
                        },
                        pageSize: pageSize,
                    }}
                    columns={columData}
                    dataSource={dataSource}
                />
            </div>
            {reportName === REPORT_NAME.AUDIT && auditData && (
                <div>
                    <h1>Audit Overview</h1>
                    <AuditPieChart data={auditData} organizationData={organizationMasterData && organizationMasterData} />
                    <AuditStatusChart data={auditData} organizationData={organizationMasterData}
                    />
                </div>
            )}
            {showDownloadOption && (
                <div>
                    <Modal
                        show={showDownloadOption}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        // dialogClassName={"modal-90w"}
                        size={"sm"}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className="fw-bold text-danger" style={{ fontSize: "1rem" }}>
                                Download
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Formik
                                initialValues={{
                                    type: "",
                                }}
                                onSubmit={handleSubmitForm}
                            // validationSchema={resetPasswordSchema}
                            >
                                {({
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                }) => (
                                    <Form onSubmit={handleSubmit}>
                                        Select Type of Certificate
                                        <div className="pe-3">
                                            <select
                                                className="form-select form-select-sm w-100"
                                                aria-label=".form-select-sm example"
                                                onChange={handleChange}
                                                name="type"
                                                value={values.type}
                                            >
                                                <option value="">
                                                    Select Type
                                                </option>
                                                {CERTIFICATE_TYPE.map((itemType: any, index: any) => {
                                                    return (
                                                        <option
                                                            key={index.toString()}
                                                            value={itemType.value}
                                                        >
                                                            {itemType.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button
                                                type="submit"
                                                className="btn btn-danger btn-block mt-3 mx-2 btn-width"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
            {filterShow && (
                <FilterModal filterShow={filterShow} setShowFilter={setShowFilter}  organizationData={organizationMasterData} data={auditData}/>
            )}
        </div>
    );
};
export default AllMasterReports;