import { format } from "date-fns";

export function fDate(date: any) {
    return format(new Date(date), 'dd/MM/yyyy');
}

export const parseHTML = (html: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || "";
};
