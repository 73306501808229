import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Button, Modal } from 'react-bootstrap';
import { alertMessage } from '../utils/AlertService';
import { useGetAllAuditFrameworkNameMutation } from '../redux-toolkit/services/auditFrameworkService';
import { allAuditFrameworkNameDetails, setAllAuditFrameworkName } from '../redux-toolkit/slices/auditFrameworkSlice';
import { useDispatch } from 'react-redux';
import { getLocalStorageData } from '../utils/LocalStorageService';
import { useAppSelector } from '../redux-toolkit/hooks';
import { useFindAllDimensionMasterMutation } from '../redux-toolkit/services/dimensionMasterService';
import { allDimensionMasterDetails, setAllDimensionMaster } from '../redux-toolkit/slices/dimensionMasterSlice';
import { DatePicker, Space, Table } from 'antd';
import { Excel } from "antd-table-saveas-excel";
import { BsDownload } from 'react-icons/bs';
import { useFindAllGroupMasterMutation } from '../redux-toolkit/services/groupMasterService';
import { useFindAllProcessMasterMutation } from '../redux-toolkit/services/processMasterService';
import { allGroupMasterDetails, setAllGroupMaster } from '../redux-toolkit/slices/groupMasterSlice';
import { allProcessMasterDetails, setAllProcessMaster } from '../redux-toolkit/slices/processMasterSlice';
import Loading from './Loading';

const { RangePicker } = DatePicker;

type Props = {
  filterShow: any;
  setShowFilter: any;
  organizationData: [];
  data: any;
};

const FilterModal = (props: Props) => {

  const localUserObj = getLocalStorageData("localUser");
  const dispatch = useDispatch();

  const statusMapping = [
    { id: 0, name: "Fully compliant" },
    { id: 1, name: "Major non compliant" },
    { id: 2, name: "Minor non compliant" },
    { id: 3, name: "Observations" },
    { id: 4, name: "Suggestions" },
    { id: 5, name: "Comment" },
    { id: 6, name: "Not applicable" },
    { id: 7, name: "On hold" },
  ];

  const [gridData, setGridData] = useState<any[]>([]); // to store data for grid representation
  const [dateRange, setDateRange] = useState<{ fromDate: string | any; toDate: string | any }>({
    fromDate: null,
    toDate: null,
  });

  // const [show, setShow] = useState(false);
  const [getAllAuditFrameworkName, auditFrameworkNameResponse] = useGetAllAuditFrameworkNameMutation();
  const [findAllDimensionMaster, findAllDimensionMasterResponse] = useFindAllDimensionMasterMutation();
  const [findAllGroupMaster, findAllGroupMasterResponse] = useFindAllGroupMasterMutation();
  const [findAllProcessMaster, findAllProcessMasterResponse] = useFindAllProcessMasterMutation();

  const auditFrameworkData: any = useAppSelector(allAuditFrameworkNameDetails);
  const allDimensions: any = useAppSelector(allDimensionMasterDetails);
  const allGroup: any = useAppSelector(allGroupMasterDetails);
  const allProcess: any = useAppSelector(allProcessMasterDetails);

  const filter = [
    { id: 0, name: "Organization", value: "organization" },
    { id: 0, name: "Organization type", value: "depOrganization" },
    { id: 1, name: "Audit Framework", value: "auditFramework" },
    { id: 2, name: "Dimension", value: "dimension" },
    { id: 2, name: "Group", value: "group" },
    { id: 2, name: "Process", value: "process" },
  ];

  const handleClose = () => {
    props.setShowFilter(false);
  };

  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [selectedValue, setSelectedValue]: any = useState();
  const [showCompanyColumn, setShowCompanyColumn] = useState(false); // Track if company column is needed

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilter(e.target.value);
    setSelectedValue(""); // Reset the selected value when filter changes
    if (e.target.value === "organization") {
      handleDropdownChange(e);
    } else if (e.target.value === "auditFramework") {
      getAllAuditFrameworkName({
        token: localUserObj?.token,
      });
      handleDropdownChange(e)
      // getAllAuditFrameworkName({ token: localUserObj?.token }).unwrap().then(() => {
      //   handleDropdownChange(e);
      // });
    } else if (e.target.value === "depOrganization") {
      handleDropdownChange(e)
    } else if (e.target.value === "dimension") {
      findAllDimensionMaster({
        token: localUserObj?.token,
      });
      handleDropdownChange(e)
      // findAllDimensionMaster({ token: localUserObj?.token }).unwrap().then(() => {
      //   handleDropdownChange(e);
      // });
    } else if (e.target.value === "group") {
      // findAllGroupMaster({ token: localUserObj?.token }).unwrap().then(() => {
      //   handleDropdownChange(e);
      // });
      findAllGroupMaster({
        token: localUserObj?.token,
      });
      handleDropdownChange(e)
    } else if (e.target.value === "process") {
      findAllProcessMaster({
        token: localUserObj?.token,
      });
      handleDropdownChange(e)
      // findAllProcessMaster({ token: localUserObj?.token }).unwrap().then(() => {
      //   handleDropdownChange(e);
      // });
    }
  };

  useEffect(() => {
    if (dateRange.fromDate !== null && dateRange.toDate !== null) {
      handleDropdownChange(selectedValue);
    }
  }, [dateRange]);

  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>, fromDate?: any, toDate?: any, fromDateChange?: string) => {
    let localSelectedValue: any = "";
    if (e?.target?.value) {
      localSelectedValue = e?.target?.value
    } else if (typeof (e) === 'string') {
      localSelectedValue = e;
    }
    setSelectedValue(localSelectedValue);

    let updatedChartData: any = {
      series: [],
      options: {
        xaxis: {
          categories: [],
        },
      },
    };

    let updatedGridData = [];

    // Apply Date Range Filter
    let filteredData = props.data;
    if (fromDateChange === "dateChange") {
      if (fromDate && toDate) {
        filteredData = props.data.filter((audit: any) => {
          const auditDate = new Date(audit.createdDate).toISOString().split("T")[0]; // Convert to YYYY-MM-DD
          return (!dateRange.fromDate || auditDate >= dateRange.fromDate) && (!dateRange.toDate || auditDate <= dateRange.toDate);
        });
      }
    } else {
      if (dateRange.fromDate && dateRange.toDate) {
        filteredData = props.data.filter((audit: any) => {
          const auditDate = new Date(audit.createdDate).toISOString().split("T")[0]; // Convert to YYYY-MM-DD
          return (!dateRange.fromDate || auditDate >= dateRange.fromDate) && (!dateRange.toDate || auditDate <= dateRange.toDate);
        });
      }
    }

    // 'organization' case
    if (selectedFilter === "organization"
      && localSelectedValue !== "depOrganization"
      && localSelectedValue !== "auditFramework"
      && localSelectedValue !== "dimension"
      && localSelectedValue !== "group"
      && localSelectedValue !== "process") {
      setShowCompanyColumn(false); // Reset Company column visibility
      if (props.organizationData) {
        const selectedOrg: any = props.organizationData?.find((org: any) => org.id === parseInt(localSelectedValue));
        const dependentOrgs = props.organizationData?.filter(
          (org: any) => org.dependentOrgCode === selectedOrg.name || org.id === parseInt(localSelectedValue)
        );

        // const categories = dependentOrgs.map((org: any) => org.name);
        const categories = [selectedOrg.name];

        // const series = statusMapping.map((status) => {
        //   const data = dependentOrgs.map((org: any) => {
        //     const auditsForOrg = filteredData.filter(
        //       (audit: any) =>
        //         audit.organizationName === org.name ||
        //         audit.dependentOrgCode === org.code
        //     );
        //     return auditsForOrg.filter((audit: any) => audit.overallStatus === status.name).length;
        //   });

        //   return {
        //     name: status.name,
        //     data,
        //   };
        // });

        const series = statusMapping.map((status) => ({
          name: status.name,
          data: dependentOrgs?.map((org: any) =>
            filteredData.filter(
              (audit: any) =>
                (audit.organizationName === org.name || audit.dependentOrgCode === org.code) &&
                audit.overallStatus === status.name
            ).length
          ) || [], // Default to empty array if `dependentOrgs` is undefined
        }));

        updatedChartData = {
          series,
          options: {
            ...updatedChartData.options,
            xaxis: {
              categories,
            },
            title: {
              text: `Audit Status Based On Organization`,
              align: "center",
            },
          },
        };

        updatedGridData = categories.map((orgName: string) => {
          const rowData: any = {
            name: orgName,  // Ensure 'name' is being set correctly
          };

          statusMapping.forEach((status: any) => {
            rowData[status.name] = series
              .filter((s: any) => s.name === status.name)
              .map((s: any) => s.data[categories.indexOf(orgName)])[0];
          });

          return rowData;
        });
      }
    }
    // 'depOrganization' case
    else if (localSelectedValue === "depOrganization") {
      setShowCompanyColumn(false); // Reset Company column visibility
      const parentOrgs = props.organizationData.filter((org: any) => org.organizationType === "parent");

      const auditResults = parentOrgs.map((parentOrg: any) => {
        const dependentAudits = filteredData.filter((audit: any) => audit.dependentOrgCode === parentOrg.name);
        return {
          parentOrgName: parentOrg.name,
          parentOrgCode: parentOrg.code,
          audits: dependentAudits.map((audit: any) => ({
            auditName: audit.name,
            overallStatus: audit.overallStatus
          }))
        };
      });

      const categoriesGrouped = auditResults.map((org: any) => org.parentOrgName);

      const seriesGrouped = statusMapping.map((status: any) => ({
        name: status.name,
        data: auditResults.map((org: any) =>
          org.audits.filter((audit: any) => audit.overallStatus === status.name).length
        ),
      }));

      updatedChartData = {
        series: seriesGrouped,
        options: {
          chart: {
            type: "bar",
            height: 350,
            stacked: false,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "50%",
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: categoriesGrouped,
            title: {
              text: "Organizations",
            },
          },
          title: {
            text: `Audit Status Based On Organization Type`,
            align: "center",
          },
          yaxis: {
            title: {
              text: "Audit Count",
            },
          },
          legend: {
            position: "top",
          },
          colors: ["#1E88E5", "#FDD835", "#D32F2F"],
          tooltip: {
            shared: true,
            intersect: false,
          },
        },
      };

      updatedGridData = categoriesGrouped.map((parentOrgName: string) => {
        const rowData: any = {
          name: parentOrgName, // Ensure 'parentOrg' is populated correctly
        };

        statusMapping.forEach((status: any) => {
          rowData[status.name] = seriesGrouped
            .filter((s: any) => s.name === status.name)
            .map((s: any) => s.data[categoriesGrouped.indexOf(parentOrgName)])[0];
        });

        return rowData;
      });
    }

    // 'auditFramework' case
    else if (localSelectedValue === "auditFramework") {
      setShowCompanyColumn(false); // Reset Company column visibility
      const auditFramework = auditFrameworkData && auditFrameworkData.map((framework: any) => {
        const auditsForFramework = filteredData.filter(
          (audit: any) => audit.auditFrameworkName === framework.name
        );

        const statusCounts = statusMapping.map((status: any) => {
          return auditsForFramework.filter(
            (audit: any) => audit.overallStatus === status.name
          ).length;
        });

        return {
          frameworkName: framework.name,
          statusCounts,
        };
      });

      const categories = auditFramework && auditFramework.map(
        (framework: any) => framework.frameworkName
      );

      const series = statusMapping.map((status: any, index: number) => {
        return {
          name: status.name,
          data: auditFramework && auditFramework.map(
            (framework: any) => framework.statusCounts[index]
          ),
        };
      });

      // const series = statusMapping.map((status: any) => {
      //   return {
      //     name: status.name,
      //     data: auditFramework ? auditFramework.map((framework: any) => {
      //         // Ensure statusCounts exists and has valid data
      //         const statusCount = framework.statusCounts?.find(
      //           (sc: any) => sc.statusName === status.name
      //         );
      //         return statusCount ? statusCount.count : 0; // Default to 0 if not found
      //       })
      //       : [],
      //   };
      // });

      updatedChartData = {
        series,
        options: {
          ...updatedChartData.options,
          xaxis: {
            categories,
            title: {
              text: "Audit Framework",
            },
          },
          title: {
            text: `Audit Status for Audit Framework`,
            align: "center",
          },
        },
      };

      updatedGridData = categories.map((frameworkName: string) => {
        const rowData: any = {
          name: frameworkName,  // Ensure 'auditFramework' is populated correctly
        };

        statusMapping.forEach((status: any) => {
          rowData[status.name] = series
            .filter((s: any) => s.name === status.name)
            .map((s: any) => s.data[categories.indexOf(frameworkName)])[0];
        });

        return rowData;
      });
    }

    // 'dimension' case
    else if (localSelectedValue === "dimension") {
      setShowCompanyColumn(false); // Reset Company column visibility
      const categories = allDimensions && allDimensions.map((dim: any) => dim.name);

      // const series = statusMapping.map((status: any) => {
      //   const data = allDimensions?.map((dim: any) => {
      //     const auditsForDimension = filteredData.filter((audit: any) =>
      //       audit.auditFrameworkAuditMappings.some(
      //         (mapping: any) => mapping.dimensionName === dim.name
      //       )
      //     );

      //     return auditsForDimension.filter(
      //       (audit: any) =>
      //         audit.auditFrameworkAuditMappings.some(
      //           (mapping: any) => mapping.dimensionName === dim.name && audit.overallStatus === status.name
      //         )
      //     ).length;
      //   });

      //   return {
      //     name: status.name,
      //     data,
      //   };
      // });

      const series = statusMapping.map((status: any) => {
        const data = allDimensions?.map((dim: any) => {
          // Get audits that contain the selected dimension
          const auditsForDimension = filteredData.filter((audit: any) =>
            audit.auditFrameworkAuditMappings.some(
              (mapping: any) => mapping.dimensionName === dim.name
            )
          );

          // Count audits where the overallStatus matches the current status
          return auditsForDimension.filter((audit: any) => audit.overallStatus === status.name).length;
        });

        return {
          name: status.name,
          data,
        };
      });

      updatedChartData = {
        series,
        options: {
          ...updatedChartData.options,
          xaxis: {
            categories,
            title: {
              text: "Dimensions",
            },
          },
          title: {
            text: `Audit Status for Dimension`,
            align: "center",
          },
        },
      };

      updatedGridData = categories.map((dimName: string) => {
        const rowData: any = {
          name: dimName,  // Ensure 'dimension' is populated correctly
        };

        statusMapping.forEach((status: any) => {
          rowData[status.name] = series
            .filter((s: any) => s.name === status.name)
            .map((s: any) => s.data[categories.indexOf(dimName)])[0];
        });

        return rowData;
      });
    }
    // for individual audit framework
    else if (selectedFilter === "auditFramework" && localSelectedValue !== "auditFramework") {
      setShowCompanyColumn(true);
      const selectedFramework = auditFrameworkData.find(
        (framework: any) => framework.id.toString() === localSelectedValue,
      )
      if (selectedFramework) {
        // const auditsForFramework = filteredData.filter((audit: any) =>
        //   audit.auditFrameworkAuditMappings.some((mapping: any) => parseInt(mapping.auditFrameworkId) === parseInt(selectedFramework.id)),
        // )
        const auditsForFramework = filteredData.filter(
          (audit: any) => audit.auditFrameworkName === selectedFramework.name,
        )

        const categories = auditsForFramework.map((audit: any) => audit.name)

        // const series = statusMapping.map((status) => ({
        //   name: status.name,
        //   data: auditsForFramework.map(
        //     (audit: any) =>
        //       audit.auditFrameworkAuditMappings.filter(
        //         (mapping: any) =>
        //           mapping.auditFrameworkName === selectedFramework.name && audit.overallStatus === status.name,
        //       ).length,
        //   ),
        // }))

        const series = statusMapping.map((status) => ({
          name: status.name,
          data: auditsForFramework.map((audit: any) =>
            audit.auditFrameworkAuditMappings.some(
              (mapping: any) => mapping.auditFrameworkName === selectedFramework.name
            ) && audit.overallStatus === status.name
              ? 1  // ✅ Count 1 if audit matches criteria
              : 0  // ✅ Otherwise, count as 0
          ),
        }));

        updatedChartData = {
          series,
          options: {
            ...updatedChartData.options,
            xaxis: {
              categories,
              title: {
                text: "Audit Framework",
              },
            },
            title: {
              text: `Audit Status for ${selectedFramework.name}`,
              align: "center",
            },
          },
        }

        updatedGridData = categories.map((auditName: string, index: number) => {
          const rowData: any = {
            name: auditName,
            organizationName: auditsForFramework[index].organizationName,
          }

          statusMapping.forEach((status: any) => {
            rowData[status.name] = series.find((s: any) => s.name === status.name)?.data[index] || 0
          })
          return rowData
        })
      }
    }
    // for individual dimension
    else if (selectedFilter === "dimension" && localSelectedValue !== "dimension") {
      setShowCompanyColumn(true);
      const selectedDimension = allDimensions && allDimensions.find((dim: any) => dim.id.toString() === localSelectedValue)
      if (selectedDimension) {
        const auditsWithDimension = filteredData.filter((audit: any) =>
          audit.auditFrameworkAuditMappings.some((mapping: any) => mapping.dimensionName === selectedDimension.name),
        )

        const categories = auditsWithDimension.map((audit: any) => audit.name)

        // const series = statusMapping.map((status) => ({
        //   name: status.name,
        //   data: auditsWithDimension.map(
        //     (audit: any) =>
        //       audit.auditFrameworkAuditMappings.filter(
        //         (mapping: any) =>
        //           mapping.dimensionName === selectedDimension.name && audit.overallStatus === status.name,
        //       ).length,
        //   ),
        // }))

        const series = statusMapping.map((status) => ({
          name: status.name,
          data: auditsWithDimension.map((audit: any) =>
            audit.auditFrameworkAuditMappings.some(
              (mapping: any) => mapping.dimensionName === selectedDimension.name
            ) && audit.overallStatus === status.name
              ? 1  // ✅ Count 1 if condition matches
              : 0  // ✅ Otherwise, count as 0
          ),
        }));

        updatedChartData = {
          series,
          options: {
            ...updatedChartData.options,
            xaxis: {
              categories,
              title: {
                text: "Audit",
              },
            },
            title: {
              text: `Audit Status for ${selectedDimension.name} Dimension`,
              align: "center",
            },
          },
        }

        updatedGridData = categories.map((auditName: string, index: number) => {
          const rowData: any = {
            name: auditName,
            organizationName: auditsWithDimension[index].organizationName,  // Added company name
          }

          statusMapping.forEach((status: any) => {
            rowData[status.name] = series.find((s: any) => s.name === status.name)?.data[index] || 0
          })
          // console.log(rowData, 'row')

          return rowData
        })
      }
    }
    // group case
    else if (localSelectedValue === 'group') {
      setShowCompanyColumn(false); // Reset Company column visibility
      const categories = allGroup && allGroup.map((group: any) => group.name);

      // const series = statusMapping.map((status: any) => {
      //   const data = allGroup?.map((group: any) => {
      //     const auditsForGroup = filteredData.filter((audit: any) =>
      //       audit.auditFrameworkAuditMappings.some(
      //         (mapping: any) => mapping.groupMasterName === group.name
      //       )
      //     );

      //     return auditsForGroup.filter(
      //       (audit: any) =>
      //         audit.auditFrameworkAuditMappings.some(
      //           (mapping: any) => mapping.groupMasterName === group.name && audit.overallStatus === status.name
      //         )
      //     ).length;
      //   });

      //   return {
      //     name: status.name,
      //     data,
      //   };
      // });

      const series = statusMapping.map((status: any) => ({
        name: status.name,
        data: allGroup?.map((group: any) => {
          return filteredData.some((audit: any) =>
            audit.auditFrameworkAuditMappings.some(
              (mapping: any) => mapping.groupMasterName === group.name
            ) && audit.overallStatus === status.name
          ) ? 1 : 0; // ✅ Only check & count if it exists
        }),
      }));

      updatedChartData = {
        series,
        options: {
          ...updatedChartData.options,
          xaxis: {
            categories,
            title: {
              text: "Group",
            },
          },
          title: {
            text: `Audit Status for Group`,
            align: "center",
          },
        },
      };

      updatedGridData = categories.map((groupName: string) => {
        const rowData: any = {
          name: groupName,
        };

        statusMapping.forEach((status: any) => {
          rowData[status.name] = series
            .filter((s: any) => s.name === status.name)
            .map((s: any) => s.data[categories.indexOf(groupName)])[0];
        });

        return rowData;
      });
    }
    // process case
    else if (localSelectedValue === 'process') {
      setShowCompanyColumn(false); // Reset Company column visibility
      const categories = allProcess.map((process: any) => process.name);

      const series = statusMapping.map((status: any) => {
        const data = allProcess.map((process: any) => {
          const auditsForProcess = filteredData.filter((audit: any) =>
            audit.auditFrameworkAuditMappings.some(
              (mapping: any) => mapping.processName === process.name
            )
          );

          return auditsForProcess.filter(
            (audit: any) =>
              audit.auditFrameworkAuditMappings.some(
                (mapping: any) => mapping.processName === process.name && audit.overallStatus === status.name
              )
          ).length;
        });

        return {
          name: status.name,
          data,
        };
      });

      updatedChartData = {
        series,
        options: {
          ...updatedChartData.options,
          xaxis: {
            categories,
            title: {
              text: "Process",
            },
          },
          title: {
            text: `Audit Status for Process`,
            align: "center",
          },
        },
      };

      updatedGridData = categories.map((processName: string) => {
        const rowData: any = {
          name: processName,  // Ensure 'process' is populated correctly
        };

        statusMapping.forEach((status: any) => {
          rowData[status.name] = series
            .filter((s: any) => s.name === status.name)
            .map((s: any) => s.data[categories.indexOf(processName)])[0];
        });

        return rowData;
      });
    }
    //for individual group
    else if (selectedFilter === "group" && localSelectedValue !== "group") {
      setShowCompanyColumn(true);
      const selectedGroup = allGroup.find(
        (group: any) => parseInt(group.id) === parseInt(localSelectedValue),
      )
      if (selectedGroup) {
        const auditsForGroup = filteredData.filter((audit: any) => {
          const hasMatch = audit.auditFrameworkAuditMappings?.some(
            (mapping: any) => mapping.groupMasterName === selectedGroup.name
          );
          return hasMatch;
        });

        const categories = auditsForGroup.map((audit: any) => audit.name)

        const series = statusMapping.map((status) => ({
          name: status.name,
          data: auditsForGroup.map((audit: any) =>
            audit.auditFrameworkAuditMappings.some(
              (mapping: any) => mapping.groupMasterName === selectedGroup.name
            ) && audit.overallStatus === status.name ? 1 : 0 // ✅ Just check & count
          ),
        }));


        updatedChartData = {
          series,
          options: {
            ...updatedChartData.options,
            xaxis: {
              categories,
              title: {
                text: "Audit",
              },
            },
            title: {
              text: `Audit Status for ${selectedGroup.name}`,
              align: "center",
            },
          },
        }

        updatedGridData = categories.map((auditName: string, index: number) => {
          const rowData: any = {
            name: auditName,
            organizationName: auditsForGroup[index].organizationName,
          }

          statusMapping.forEach((status: any) => {
            rowData[status.name] = series.find((s: any) => s.name === status.name)?.data[index] || 0
          })
          return rowData
        })
      }
    }
    //for individual process
    else if (selectedFilter === "process" && localSelectedValue !== "process") {
      setShowCompanyColumn(true);
      const selectedProcess = allProcess.find(
        (process: any) => parseInt(process.id) === parseInt(localSelectedValue),
      )
      if (selectedProcess) {
        const auditsForProcess = filteredData.filter((audit: any) => (
          audit.auditFrameworkAuditMappings?.some(
            (mapping: any) => mapping.processName === selectedProcess.name
          )
        ));

        const categories = auditsForProcess.map((audit: any) => audit.name)

        const series = statusMapping.map((status) => ({
          name: status.name,
          data: auditsForProcess.map((audit: any) =>
            audit.auditFrameworkAuditMappings.some(
              (mapping: any) => mapping.processName === selectedProcess.name
            ) && audit.overallStatus === status.name ? 1 : 0 // ✅ Just check & count
          ),
        }));

        // const series = statusMapping.map((status) => ({
        //   name: status.name,
        //   data: auditsForProcess.map(
        //     (audit: any) =>
        //       audit.auditFrameworkAuditMappings.filter(
        //         (mapping: any) =>
        //           mapping.processName === selectedProcess.name && audit.overallStatus === status.name,
        //       ).length,
        //   ),
        // }))

        updatedChartData = {
          series,
          options: {
            ...updatedChartData.options,
            xaxis: {
              categories,
              title: {
                text: "Audit",
              },
            },
            title: {
              text: `Audit Status for ${selectedProcess.name}`,
              align: "center",
            },
          },
        }

        updatedGridData = categories.map((auditName: string, index: number) => {
          const rowData: any = {
            name: auditName,
            organizationName: auditsForProcess[index].organizationName,
          }

          statusMapping.forEach((status: any) => {
            rowData[status.name] = series.find((s: any) => s.name === status.name)?.data[index] || 0
          })
          return rowData
        })
      }
    }

    // Update state once with the final data
    setChartData(updatedChartData);
    setGridData(updatedGridData);
  };

  const getColumns = () => {
    const baseColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      // Add Company column second in grid if showCompanyColumn is true
      ...(showCompanyColumn
        ? [
          {
            title: "Company",
            dataIndex: "organizationName",
            key: "organizationName",
          },
        ]
        : []),
      ...statusMapping.map((status: any) => ({
        title: status.name,
        dataIndex: status.name,
        key: status.name,
      })),
    ];

    return baseColumns;
  };

  const renderDropdown = () => {
    switch (selectedFilter) {
      case "organization":
        return (
          <select
            className="form-select form-select-sm w-100"
            aria-label="Select Organization"
            value={selectedValue}
            onChange={handleDropdownChange}
          >
            <option value="">Select Organization</option>
            {props.organizationData.map((org: any) => (
              <option key={org.id.toString()} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
        );

      case "auditFramework":
        return (
          <select
            className="form-select form-select-sm w-100"
            aria-label="Select Audit Framework"
            value={selectedValue}
            onChange={handleDropdownChange}
          >
            <option value="">Select Audit Framework</option>
            {auditFrameworkData && auditFrameworkData.map((framework: any) => (
              <option key={framework.id} value={framework.id}>
                {framework.name}
              </option>
            ))}
          </select>
        );

      case "dimension":
        return (
          <select
            className="form-select form-select-sm w-100"
            aria-label="Select Dimension"
            value={selectedValue}
            onChange={handleDropdownChange}
          >
            <option value="">Select Dimension</option>
            {allDimensions && allDimensions.map((dimension: any) => (
              <option key={dimension.id} value={dimension.id}>
                {dimension.name}
              </option>
            ))}
          </select>
        );

      case "group":
        return (
          <select
            className="form-select form-select-sm w-100"
            aria-label="Select Group"
            value={selectedValue}
            onChange={handleDropdownChange}
          >
            <option value="">Select Group</option>
            {allGroup && allGroup.map((group: any) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        );

      case "process":
        return (
          <select
            className="form-select form-select-sm w-100"
            aria-label="Select Process"
            value={selectedValue}
            onChange={handleDropdownChange}
          >
            <option value="">Select Process</option>
            {allProcess && allProcess.map((process: any) => (
              <option key={process.id} value={process.id}>
                {process.name}
              </option>
            ))}
          </select>
        );


      default:
        return (
          <div className="mt-3 text-muted">
            <i>Please select a filter to view options.</i>
          </div>
        );
    }
  };

  const [chartData, setChartData]: any = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: false, // Ensure bars are grouped
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "Count of Statuses",
        },
      },
      legend: {
        position: "top",
      },
      colors: ["#4caf50", "#f44336", "#ff9800", "#2196f3", "#9c27b0", "#ffeb3b", "#607d8b", "#00bcd4"],
    },
  });

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setDateRange({ fromDate: dateStrings[0], toDate: dateStrings[1] });
    handleDropdownChange(selectedValue, dateStrings[0], dateStrings[1], "dateChange")
  };

  const handleExportClick = () => {
    let xlsxColumns: any;
    let xlsxDataSource: any;

    const fileName = "Filtered_Data";
    xlsxColumns = getColumns();
    xlsxDataSource = gridData;

    const excel = new Excel();
    const sheet = excel.addSheet("Audit Data");

    // ✅ Add Title Row (First Cell Contains Title)
    const titleRow = sheet.addRow();
    const titleCell = titleRow.addCell();
    titleCell.value = `Audit Data Based On ${selectedFilter.toUpperCase()}`;

    // ✅ Add Empty Cells in Title Row to Simulate Merging
    for (let i = 1; i < xlsxColumns.length; i++) {
      titleRow.addCell().value = ""; // Empty cell
    }

    if (dateRange) {
      // ✅ 2. Add Row to show FromDate and ToDate filter
      const subtitleRow = sheet.addRow();
      const subtitleCell = subtitleRow.addCell();
      subtitleCell.value = "Generated Audit Data From : " + dateRange?.fromDate + " , To : " + dateRange?.toDate;
    }

    // ✅ 4. Add an Empty Row for Spacing
    sheet.addRow();

    // ✅ Add Table Headers
    sheet.addColumns(xlsxColumns);

    // ✅ Add Data Rows
    sheet.addDataSource(xlsxDataSource);

    // ✅ Save File
    excel.saveAs(`${fileName}.xlsx`);
  };

  useEffect(() => {
    if (auditFrameworkNameResponse.isSuccess) {
      dispatch(setAllAuditFrameworkName(auditFrameworkNameResponse));
    } else if (auditFrameworkNameResponse.isError) {
      alertMessage("Error while fetching audit framewrok", "error");
    }
  }, [auditFrameworkNameResponse.isSuccess, auditFrameworkNameResponse.isError]);

  useEffect(() => {
    if (findAllDimensionMasterResponse.isSuccess) {
      dispatch(setAllDimensionMaster(findAllDimensionMasterResponse))
    } else if (findAllDimensionMasterResponse.isError) {
      alertMessage("Error while fetching dimension masters", "error");
    }
  }, [findAllDimensionMasterResponse.isSuccess, findAllDimensionMasterResponse.isError]);

  useEffect(() => {
    if (findAllProcessMasterResponse.isSuccess) {
      dispatch(setAllProcessMaster(findAllProcessMasterResponse))
    } else if (findAllProcessMasterResponse.isError) {
      alertMessage("Error while fetching process masters", "error");
    }
  }, [findAllProcessMasterResponse.isSuccess, findAllProcessMasterResponse.isError]);

  useEffect(() => {
    if (findAllGroupMasterResponse.isSuccess) {
      dispatch(setAllGroupMaster(findAllGroupMasterResponse))
    } else if (findAllGroupMasterResponse.isError) {
      alertMessage("Error while fetching group master", "error");
    }
  }, [findAllGroupMasterResponse.isSuccess, findAllGroupMasterResponse.isError]);

  return (
    <div>
      <Modal
        show={props.filterShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // dialogClassName={"modal-90w"}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold text-danger" style={{ fontSize: "1rem" }}>
            Filter
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {auditFrameworkNameResponse.isLoading ||
            findAllDimensionMasterResponse.isLoading ||
            findAllGroupMasterResponse.isLoading ||
            findAllProcessMasterResponse.isLoading ? (
            <Loading />
          ) : (
            ""
          )}
          <div>
            <div className="pe-3 mt-3">
              <label htmlFor="filterSelect" className="form-label">
                Select Filter By
              </label>
              <select
                id="filterSelect"
                className="form-select form-select-sm w-100"
                aria-label="Select Filter"
                onChange={handleFilterChange}
                value={selectedFilter}
              >
                <option value="">Select</option>
                {filter.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-3">{renderDropdown()}</div>
            <div className="w-100 mt-3">
              <div className="d-flex">
                <div>Select From and To Date</div>
              </div>
              <Space direction="vertical" size={12}>
                <RangePicker
                  name="dateRange"
                  getPopupContainer={() => document.body}
                  onChange={handleDateChange}
                />
              </Space>
            </div>
            <div className='mt-5'>
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={400}
              />
            </div>
            <div className='d-flex justify-content-end'>
              <Button
                variant="danger"
                className="d-flex align-items-center me-0"
                onClick={handleExportClick}
              >
                <BsDownload size={16} className="me-2" />
                Export
              </Button>
            </div>
            <div className='mt-3'>
              <Table
                scroll={{ x: "max-content" }}
                // columns={columns}
                columns={getColumns()}  // Use the dynamic columns function
                dataSource={gridData}
                // rowKey="dimension"
                pagination={false}
                bordered
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )

};

export default FilterModal;