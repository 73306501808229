import { Button, Modal } from "react-bootstrap";
import { ACTION_TYPE, REPORT_NAME } from "../../constant/reportType";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { alertMessage } from "../../utils/AlertService";
import { useEffect, useState } from "react";
import Loading from "../Loading";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { useAppSelector } from "../../redux-toolkit/hooks";
import { useFindAllOrganizationMasterMutation, useGetOrganizationMasterByIdMutation, useSaveOrganizationMasterMutation, useUpdateOrganizationMasterMutation } from "../../redux-toolkit/services/organizationMasterService";
import { allOrganizationMasterDetails, setAllOrganizationMaster } from "../../redux-toolkit/slices/organizationMasterSlice";
import { useGetAllCountryMutation } from "../../redux-toolkit/services/countryService";
import { useGetStateMutation } from "../../redux-toolkit/services/stateService";
import { getAllcountryDetail, setcountry } from "../../redux-toolkit/slices/countrySlice";
import { getFindStateByCountryNameDetail, setStates } from "../../redux-toolkit/slices/statesSlice";
import { useGetUserByIdMutation, useSaveUserMutation, useUpdateUserMutation } from "../../redux-toolkit/services/allUserService";
import { useGetAllUserRoleMutation, useGetUserRoleByIdMutation, useSaveUserRoleMutation, useUpdateUserRoleMutation } from "../../redux-toolkit/services/userRoleService";
import { useGetAllUserGroupMutation, useGetUserGroupByIdMutation, useSaveUserGroupMutation, useUpdateUserGroupMutation } from "../../redux-toolkit/services/userGroupService";
import { getAllUserDetails } from "../../redux-toolkit/slices/allUserSlice";
import { getAllUserRoleDetails, setAllUserRole } from "../../redux-toolkit/slices/allUserRoleSlice";
import { getAllUserGroupDetails, setAllUserGroup } from "../../redux-toolkit/slices/allUserGroupSlice";
import { allPackagesDetails, setAllPackages } from "../../redux-toolkit/slices/packageMasterSlice";
import { useFindAllPackagesMutation } from "../../redux-toolkit/services/packageMasterService";
import * as Yup from "yup";
import { Link } from "react-router-dom";

type Props = {
    show: boolean;
    setShow: any;
    actionType: ACTION_TYPE;
    reportName: string;
    data: any;
    setCall: any;
};

const UserSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter First name!"),
    lastName: Yup.string().required("Please enter Last name!"),
    phoneNumber: Yup.string()
        // .required('Field is required')
        .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            'Phone number is not valid'
        )
        .min(10, 'Contact Number must be 10 digits')
        .max(10, 'Contact Number must be 10 digits')
        .trim(),

    briefProfile: Yup.string()
        // .required("Please enter Description!")
        .min(10, "Description is Too Short!")
        .max(100, "Description Too Long!"),

    email: Yup.string().required("Please enter Email Address!"),
    selectedPackage: Yup.string().required("Please select package to proceed!"),
    //To do uncomment these below 2 validation if showing role and group dropodown instead of input field
    group: Yup.string().required("Please select group"),
    role: Yup.string().required("Please select role"),

    termsAndConditionsCheckbox: Yup.string().required("Please agree to the Terms and Conditions"),
    privacyPolicyCheckbox: Yup.string().required("Please agree to the Privacy Policy"),
});

const UserMasterReportData = (props: Props) => {

    const localUserObj: any = getLocalStorageData("localUser");
    const dispatch = useDispatch();

    //states
    const [getByIdData, setGetByIdData]: any = useState([]);
    const [file, setFile]: any = useState<File | null>(null);
    const [icon, setIcon]: any = useState<File | null>(null);
    const [fileName, setFileName]: any = useState("");
    const [imageBase64, setImageBase64] = useState() as any;
    const [newImage, setNewImage] = useState(null) as any;
    const [selectedCountry, setSelectedCountry]: any = useState();
    const [orgId, setOrgId]: any = useState();

    //save
    const [saveOrganizationMaster, saveOrganizationMasterResponse] = useSaveOrganizationMasterMutation();
    const [saveUser, saveUserResponse] = useSaveUserMutation();
    const [saveUserRole, saveUserRoleResponse] = useSaveUserRoleMutation();
    const [saveUserGroup, saveUserGroupResponse] = useSaveUserGroupMutation();

    //update
    const [updateOrganizationMaster, updateOrganizationMasterResponse] = useUpdateOrganizationMasterMutation();
    const [updateUser, updateUserResponse] = useUpdateUserMutation();
    const [updateUserRole, updateUserRoleResponse] = useUpdateUserRoleMutation();
    const [updateUserGroup, updateUserGroupResponse] = useUpdateUserGroupMutation();

    //getById mutation
    const [getOrganizationMasterById, organizationMasterByIdResponse] = useGetOrganizationMasterByIdMutation();
    const [getUserById, userByIdResponse]: any = useGetUserByIdMutation();
    const [getAllUserRole, getAllUserRoleResponse] = useGetAllUserRoleMutation();
    const [getAllUserGroup, getAllUserGroupResponse] = useGetAllUserGroupMutation();
    const [getUserRoleById, userRoleByIdResponse]: any = useGetUserRoleByIdMutation();
    const [getUserGroupById, userGroupByIdResponse]: any = useGetUserGroupByIdMutation();

    //getData
    const [getAllOrganizations, allOrganizationsResponse] = useFindAllOrganizationMasterMutation();
    const [getCountry, getCountryResponse] = useGetAllCountryMutation();
    const [getState, getStateResponse] = useGetStateMutation();
    const [findAllPackages, packagesResponse] = useFindAllPackagesMutation();

    //Data
    const organizationData: any = useAppSelector(allOrganizationMasterDetails);
    const allCountries: any = useAppSelector(getAllcountryDetail);
    const allStates: any = useAppSelector(getFindStateByCountryNameDetail);
    const usersData: any = useAppSelector(getAllUserDetails);
    const allUserRole: any = useAppSelector(getAllUserRoleDetails);
    const allUserGroup: any = useAppSelector(getAllUserGroupDetails);
    const allPackagesData: any = useAppSelector(allPackagesDetails);

    useEffect(() => {
        if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
            if (props.reportName === REPORT_NAME.ORGANIZATION_MASTER) {
                getOrganizationMasterById({
                    id: props?.data?.id,
                    token: localUserObj?.token
                });
                getCountry({});
            } else if (props.reportName === REPORT_NAME.USER_MASTER) {
                getAllOrganizations({ token: localUserObj.token })
                getUserById({
                    id: props?.data?.id,
                    token: localUserObj?.token
                })
                getCountry({});
                getAllUserGroup({ token: localUserObj.token });
                getAllUserRole({ token: localUserObj.token });
                findAllPackages({});
            } else if (props.reportName === REPORT_NAME.USER_ROLE) {
                getUserRoleById({
                    token: localUserObj.token,
                    id: props?.data?.id,
                });
            } else if (props.reportName === REPORT_NAME.USER_GROUP) {
                getUserGroupById({
                    token: localUserObj.token,
                    id: props?.data?.id,
                });
            }
        } else if (props.actionType === ACTION_TYPE.ADD_ACTION) {
            if (props.reportName === REPORT_NAME.ORGANIZATION_MASTER) {
                getCountry({});
            } else if (props.reportName === REPORT_NAME.USER_MASTER) {
                getCountry({});
                getAllUserGroup({ token: localUserObj.token });
                getAllUserRole({ token: localUserObj.token });
                findAllPackages({});
            }
        }
    }, [props.reportName]);

    const handleClose = () => {
        props.setShow(false);
    };

    const handleSubmit = (values: any) => {

        if (props.actionType === ACTION_TYPE.ADD_ACTION) {

            if (props.reportName === REPORT_NAME.ORGANIZATION_MASTER) {

                const payload = {
                    status: true,
                    id: 0,
                    name: values.name,
                    code: values.code,
                    contactPerson: values.contactPerson,
                    contactEmail: values.contactEmail,
                    contactPhone: values.contactPhone,
                    website: values.website,
                    city: values.city,
                    state: parseInt(values.state),
                    country: parseInt(selectedCountry),
                    dependentOrgCode: values.dependentOrgCode,
                    organizationType: values.organizationType,
                }

                const formData = new FormData();
                formData.append('organizationMasterReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
                formData.append('icon', icon);

                saveOrganizationMaster({
                    token: localUserObj?.token,
                    payload: formData,
                });
            } else if (props.reportName === REPORT_NAME.USER_MASTER) {
                const payload = {
                    address: values.address,
                    briefProfile: values.briefProfile,
                    cityId: values.city,
                    countryId: parseInt(selectedCountry), //values.country,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    linkedinRef: values.linkedinRef,
                    organizationName: values.organizationName,
                    organizationId: values.organizationId,
                    password: values.email,
                    phoneNumber: values.phoneNumber,
                    postalCode: values.pinCode,
                    stateId: parseInt(values.state),
                    userGroupId: parseInt(values.group),
                    userRoleId: parseInt(values.role),
                    roleIds: [parseInt(values.role)],
                    username: values.email,
                    termsAndCondition: values.termsAndConditionsCheckbox,
                    privacyPolicy: values.privacyPolicyCheckbox,
                    packageId: parseInt(values.selectedPackage),
                    isUserSubscribed: true,
                }

                saveUser({
                    token: localUserObj?.token,
                    payload: payload
                });
            } else if (props.reportName === REPORT_NAME.USER_ROLE) {
                saveUserRole({
                    token: localUserObj.token,
                    payload: {
                        status: true,
                        id: 0,
                        name: values.name,
                        permissionIds: [],
                        userGroupId: 1,
                    },
                });
            } else if (props.reportName === REPORT_NAME.USER_GROUP) {
                saveUserGroup({
                    token: localUserObj.token,
                    payload: {
                        status: true,
                        id: 0,
                        name: values.name,
                    },
                });
            }
        } else if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
            if (props.reportName === REPORT_NAME.ORGANIZATION_MASTER) {

                const payload = {
                    status: props.data.status,
                    id: props?.data?.id,
                    name: values.name,
                    code: values.code,
                    contactPerson: values.contactPerson,
                    contactEmail: values.contactEmail,
                    contactPhone: values.contactPhone,
                    website: values.website,
                    city: values.city,
                    state: parseInt(values.state),
                    country: parseInt(selectedCountry),
                    dependentOrgCode: values.dependentOrgCode,
                    organizationType: values.organizationType,
                }

                const formData = new FormData();
                formData.append('organizationMasterReqDTO', new Blob([JSON.stringify(payload)], { type: 'application/json' }));

                if (icon) {
                    formData.append('icon', icon);
                } else if (imageBase64) {
                    try {
                        const base64File = base64ToFile(imageBase64, 'icon');
                        formData.append('icon', base64File);
                    } catch (error) {
                        console.error('Error converting base64 to file:', error);
                    }
                }

                updateOrganizationMaster({
                    token: localUserObj?.token,
                    payload: formData,
                    id: props?.data?.id,
                });
            } else if (props.reportName === REPORT_NAME.USER_MASTER) {

                const orgObj = organizationData && organizationData
                    .find((item: any) => {
                        return parseInt(item.id) === parseInt(values.organizationId);
                    });

                const payload = {
                    id: props?.data?.id,
                    status: props.data.enabled,
                    address: values.address,
                    briefProfile: values.briefProfile,
                    cityId: values.city,
                    countryId: parseInt(selectedCountry), //values.country,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    linkedinRef: values.linkedinRef,
                    organizationName: orgObj.name,
                    organizationId: values.organizationId,
                    password: values.email,
                    phoneNumber: values.phoneNumber,
                    postalCode: values.pinCode,
                    stateId: parseInt(values.state),
                    userGroupId: parseInt(values.group),
                    userRoleId: parseInt(values.role),
                    roleIds: [parseInt(values.role)],
                    username: values.email,
                    termsAndCondition: values.termsAndConditionsCheckbox,
                    privacyPolicy: values.privacyPolicyCheckbox,
                    packageId: parseInt(values.selectedPackage),
                    isUserSubscribed: true,
                }

                updateUser({
                    token: localUserObj?.token,
                    id: props?.data?.id,
                    payload: payload
                })

            } else if (props.reportName === REPORT_NAME.USER_ROLE) {
                updateUserRole({
                    token: localUserObj.token,
                    // id: props?.data?.id,
                    payload: {
                        status: props.data.status,
                        id: props?.data?.id,
                        name: values.name,
                        permissionIds: [],
                    },
                });
            } else if (props.reportName === REPORT_NAME.USER_GROUP) {
                updateUserGroup({
                    token: localUserObj.token,
                    id: props?.data?.id,
                    payload: {
                        status: props.data.status,
                        id: props?.data?.id,
                        name: values.name,
                    },
                });
            }
        }
    };

    const base64ToFile = (base64String: any, filename: any) => {
        // Check if the base64 string includes the prefix
        let base64Data = base64String;
        let mime = '';

        if (base64String.includes(',')) {
            const arr = base64String.split(',');
            mime = arr[0].match(/:(.*?);/)[1];
            base64Data = arr[1];
        } else {
            // Default to image/jpeg if the prefix is missing
            mime = 'image/jpeg';
        }

        const bstr = atob(base64Data);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        const ext = mime.split('/')[1];
        return new File([u8arr], `${filename}.${ext}`, { type: mime });
    };

    const handleIconChange = (e: any, setFieldValue: any) => {
        setFieldValue("icon", e.target.files[0]);

        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setNewImage(reader.result);
                setImageBase64(null); // Clear the initial image if a new one is uploaded
            };
            reader.readAsDataURL(file);
        }

        const fileName = e.target.files[0].name;
        // setFileName(fileName)
        const extension = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(extension, fileName.length).toLowerCase();

        if (extFile == "jpg" || extFile == "svg" || extFile == "png") {
            setIcon(e.target.files[0]);
        } else {
            alertMessage("Only jpg, svg and png are allowed!", "error");
        }
    }

    // upload evidence file...............................
    const handleFileChange = (e: any) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name)
    };

    const handleSelectCountry = (event: any) => {
        setSelectedCountry(event.target.value);
        if (event) {
            getState({
                countryId: event.target.value,
            });
        }
    };

    useEffect(() => {
        if (saveOrganizationMasterResponse.isSuccess) {
            alertMessage("Organization saved successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (saveOrganizationMasterResponse.isError) {
            alertMessage("Error while saving organization", "error");
        }
    }, [saveOrganizationMasterResponse.isSuccess, saveOrganizationMasterResponse.isError]);

    //update response useEffects
    useEffect(() => {
        if (updateOrganizationMasterResponse.isSuccess) {
            alertMessage("Organization updated successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (updateOrganizationMasterResponse.isError) {
            alertMessage("Error while updating organization", "error");
        }
    }, [updateOrganizationMasterResponse.isSuccess, updateOrganizationMasterResponse.isError]);

    //getByIdResponse
    useEffect(() => {
        if (organizationMasterByIdResponse.isSuccess) {
            setGetByIdData(organizationMasterByIdResponse.data);
            setSelectedCountry(parseInt(organizationMasterByIdResponse.data.country))
            getState({ countryId: organizationMasterByIdResponse.data.country });
            setImageBase64(organizationMasterByIdResponse?.data?.icon)

        } else if (organizationMasterByIdResponse.isError) {
            alertMessage("Error while getting organization data", "error");
        }
    }, [organizationMasterByIdResponse.isSuccess, organizationMasterByIdResponse.isError]);

    //getData response
    useEffect(() => {
        if (allOrganizationsResponse.isSuccess) {
            dispatch(setAllOrganizationMaster(allOrganizationsResponse));
        } else if (allOrganizationsResponse.isError) {
            alertMessage("Error fetching companies", "error");
        }
    }, [allOrganizationsResponse.isSuccess, allOrganizationsResponse.isError]);

    useEffect(() => {
        if (getCountryResponse.isSuccess) {
            dispatch(setcountry(getCountryResponse));
        } else if (getCountryResponse.isError) {
            alertMessage("Error while fetching countries", "error");
        }
    }, [getCountryResponse.isSuccess, getCountryResponse.isError]);

    useEffect(() => {
        if (getStateResponse.isSuccess) {
            dispatch(setStates(getStateResponse));
        } else if (getStateResponse.isError) {
            alertMessage("Error while fetchin states", "error");
        }
    }, [getStateResponse.isSuccess, getStateResponse.isError]);

    //get allpackages.......
    useEffect(() => {
        if (packagesResponse.isSuccess) {
            dispatch(setAllPackages(packagesResponse));
        } else if (packagesResponse.isError) {
            alertMessage("Error while fetching packages", "error");
        }
    }, [packagesResponse.isSuccess, packagesResponse.isError]);


    useEffect(() => {
        if (userByIdResponse.isSuccess) {
            setGetByIdData(userByIdResponse.data);
            setSelectedCountry(parseInt(userByIdResponse.data.countryId))
            getState({ countryId: userByIdResponse.data.countryId });
            setImageBase64(userByIdResponse?.data?.icon)

        } else if (userByIdResponse.isError) {
            alertMessage("Error while getting user data", "error");
        }
    }, [userByIdResponse.isSuccess, userByIdResponse.isError]);


    useEffect(() => {
        if (getByIdData.length > 0) {
            const orgObj = organizationData && organizationData
                .find((item: any) => {
                    return item.name === userByIdResponse?.organizationName;
                });
            setOrgId(parseInt(orgObj.id));
        }
    }, [getByIdData]);

    useEffect(() => {
        if (getAllUserRoleResponse.isSuccess) {
            dispatch(setAllUserRole(getAllUserRoleResponse));
        } else if (getAllUserRoleResponse.isError) {
            alertMessage("Error while fetching user role", "error");
        }
    }, [getAllUserRoleResponse.isSuccess, getAllUserRoleResponse.isError]);

    useEffect(() => {
        if (getAllUserGroupResponse.isSuccess) {
            dispatch(setAllUserGroup(getAllUserGroupResponse));
        } else if (getAllUserGroupResponse.isError) {
            alertMessage("Error while fetching user group", "error");
        }
    }, [getAllUserGroupResponse.isSuccess, getAllUserGroupResponse.isError]);

    useEffect(() => {
        if (saveUserResponse.isSuccess) {
            alertMessage("User added successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (saveUserResponse.isError) {
            let response: any = saveUserResponse?.error;
            let msg: any = response.data?.serviceErrors[0]?.msg;
            alertMessage(msg, "error");
        }
    }, [saveUserResponse.isSuccess, saveUserResponse.isError]);

    useEffect(() => {
        if (updateUserResponse.isSuccess) {
            alertMessage("User updated successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (updateUserResponse.isError) {
            let response: any = updateUserResponse?.error;
            let msg: any = response.data?.serviceErrors[0]?.msg;
            alertMessage(msg, "error");
        }
    }, [updateUserResponse.isSuccess, updateUserResponse.isError]);

    //user role
    useEffect(() => {
        if (saveUserRoleResponse.isSuccess) {
            alertMessage("User role added successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (saveUserRoleResponse.isError) {
            let response: any = saveUserRoleResponse?.error;
            let msg: any = response.data?.serviceErrors[0]?.msg;
            alertMessage(msg, "error");
        }
    }, [saveUserRoleResponse.isSuccess, saveUserRoleResponse.isError]);

    useEffect(() => {
        if (updateUserRoleResponse.isSuccess) {
            alertMessage("User role updated successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (updateUserRoleResponse.isError) {
            let response: any = updateUserRoleResponse?.error;
            let msg: any = response.data?.serviceErrors[0]?.msg;
            alertMessage(msg, "error");
        }
    }, [updateUserRoleResponse.isSuccess, updateUserRoleResponse.isError]);

    //user group
    useEffect(() => {
        if (saveUserGroupResponse.isSuccess) {
            alertMessage("User group added successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (saveUserGroupResponse.isError) {
            let response: any = saveUserGroupResponse?.error;
            let msg: any = response.data?.serviceErrors[0]?.msg;
            alertMessage(msg, "error");
        }
    }, [saveUserGroupResponse.isSuccess, saveUserGroupResponse.isError]);

    useEffect(() => {
        if (updateUserGroupResponse.isSuccess) {
            alertMessage("User group updated successfully", "success");
            props.setCall("Y");
            handleClose();
        } else if (updateUserGroupResponse.isError) {
            let response: any = updateUserGroupResponse?.error;
            let msg: any = response?.error ? response.error.data.error : "Error while updating";
            alertMessage(msg, "error");
        }
    }, [updateUserGroupResponse.isSuccess, updateUserGroupResponse.isError]);

    useEffect(() => {
        if (userRoleByIdResponse.isSuccess) {
            setGetByIdData(userRoleByIdResponse.data);
            //   let selectedPermissionIds = userRoleByIdResponse?.data?.permissions.map((permission: any) => permission.id);
            //   setSelectedPermissions(selectedPermissionIds);

        } else if (userRoleByIdResponse.isError) {
            alertMessage("Error while fetcing user role", "error");
        }
    }, [userRoleByIdResponse.isSuccess, userRoleByIdResponse.isError]);

    useEffect(() => {
        if (userGroupByIdResponse.isSuccess) {
            setGetByIdData(userGroupByIdResponse.data);
        } else if (userGroupByIdResponse.isError) {
            alertMessage("Error while fetching user group", "error");
        }
    }, [userGroupByIdResponse.isSuccess, userGroupByIdResponse.isError]);

    return (
        <>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                // dialogClassName={"modal-90w"}
                {...((props.reportName === REPORT_NAME.USER_ROLE || props.reportName === REPORT_NAME.USER_GROUP)
                    ? { dialogClassName: "modal-90w" }
                    : { size: props.reportName === REPORT_NAME.AUDIT ? "xl" : "lg" })}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold text-danger" style={{ fontSize: "1rem" }}>
                        {props.actionType === ACTION_TYPE.ADD_ACTION ? "Add" : "Update"}{" "}
                        {props.reportName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {saveOrganizationMasterResponse.isLoading ||
                        saveUserResponse.isLoading ||
                        saveUserRoleResponse.isLoading ||
                        saveUserGroupResponse.isLoading ||
                        updateOrganizationMasterResponse.isLoading ||
                        updateUserResponse.isLoading ||
                        updateUserRoleResponse.isLoading ||
                        updateUserGroupResponse.isLoading ||
                        organizationMasterByIdResponse.isLoading ||
                        userByIdResponse.isLoading ||
                        getAllUserRoleResponse.isLoading ||
                        getAllUserGroupResponse.isLoading ||
                        userRoleByIdResponse.isLoading ||
                        userGroupByIdResponse.isLoading ||
                        allOrganizationsResponse.isLoading ||
                        getCountryResponse.isLoading ||
                        getStateResponse.isLoading ||
                        packagesResponse.isLoading ? (
                        <Loading />
                    ) : (
                        ""
                    )}
                    <Formik
                        // validationSchema={props.reportName === REPORT_NAME.USER_MASTER ? UserSchema : ""}
                        enableReinitialize={true}
                        initialValues={(props.reportName === REPORT_NAME.ORGANIZATION_MASTER) ? {
                            name: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.name
                                : "",
                            code: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.code
                                : "",
                            contactPerson: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.contactPerson
                                : "",
                            contactEmail: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.contactEmail
                                : "",
                            contactPhone: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.contactPhone
                                : "",
                            website: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.website
                                : "",
                            city: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.city
                                : "",
                            state: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.state
                                : "",
                            country: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && parseInt(getByIdData.country)
                                : "",
                            dependentOrgCode: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.dependentOrgCode
                                : "",
                            organizationType: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.organizationType
                                : "",
                        } : (props.reportName === REPORT_NAME.USER_ROLE) ? {
                            name: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.name
                                : "",
                        } : (props.reportName === REPORT_NAME.USER_GROUP) ? {
                            name: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.name
                                : "",
                        } : {
                            firstName: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.firstName
                                : "",
                            lastName: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.lastName
                                : "",
                            email: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.email
                                : "",
                            phoneNumber: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.phoneNumber
                                : "",
                            linkedinRef: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.name
                                : "",
                            // organizationName: ACTION_TYPE.UPDATE_ACTION
                            //     ? getByIdData && getByIdData.organizationName
                            //     : "",
                            organizationId: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.organizationId
                                : "",
                            group: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.userGroup?.id
                                : "",
                            role: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.userRole?.id
                                : "",
                            address: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.address
                                : "",
                            state: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.stateId
                                : "",
                            city: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.city
                                : "",
                            country: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.countryId
                                : "",
                            pinCode: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.postalCode
                                : "",
                            briefProfile: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.name
                                : "",
                            termsAndConditionsCheckbox: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.termsAndCondition ? getByIdData.termsAndCondition : false
                                : true,
                            privacyPolicyCheckbox: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.privacyPolicy ? getByIdData.privacyPolicy : false
                                : true,
                            selectedPackage: ACTION_TYPE.UPDATE_ACTION
                                ? getByIdData && getByIdData.packageId
                                : "",
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({ values, handleChange, setFieldValue, errors }) => (
                            <Form>

                                {props.reportName === REPORT_NAME.ORGANIZATION_MASTER ? (
                                    <div>
                                        <div>
                                            <div className="d-flex">
                                                <div> {props.reportName} Name</div>
                                                <div style={{ color: "red" }}>*</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.name}
                                                name="name"
                                            // required
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div> {props.reportName} Code</div>
                                                {/* <div style={{ color: "red" }}>*</div> */}
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.code}
                                                name="code"
                                            // required
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Contact Person</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.contactPerson}
                                                name="contactPerson"
                                            // required
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Contact Email</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="email"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.contactEmail}
                                                name="contactEmail"
                                            // required
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Contact Phone</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.contactPhone}
                                                name="contactPhone"
                                            // required
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Webiste</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.website}
                                                name="website"
                                            // required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <select
                                                className="form-select form-textbox"
                                                // ${errors.country ? 'is-invalid' : ''}
                                                aria-label="selectColumn"
                                                onChange={handleSelectCountry}
                                                aria-placeholder="Country"
                                                name="country"
                                                value={selectedCountry}
                                            >
                                                <option>Country</option>
                                                {allCountries && allCountries.map((country: any, index: any) => {
                                                    return (
                                                        <option key={index} value={country.id}>
                                                            {country.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <select
                                                className="form-select form-textbox bg-secondary-light border-secondary-light"
                                                // ${errors.state ? 'is-invalid' : ''}
                                                aria-label="selectColumn"
                                                onChange={handleChange}
                                                aria-placeholder="State"
                                                name="state"
                                                value={values.state}
                                            >
                                                <option>State</option>
                                                {allStates && allStates.map((state: any, index: any) => {
                                                    return (
                                                        <option key={index} value={state.id}>
                                                            {state.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                className="form-control form-textbox"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.city}
                                                name="city"
                                                placeholder="City"
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex">
                                                <div>Dependent Organization Code</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.dependentOrgCode}
                                                name="dependentOrgCode"
                                            // required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <select
                                                className="form-select form-textbox bg-secondary-light border-secondary-light"
                                                // ${errors.state ? 'is-invalid' : ''}
                                                aria-label="selectColumn"
                                                onChange={handleChange}
                                                aria-placeholder="Organization Type"
                                                name="organizationType"
                                                value={values.organizationType}
                                            >
                                                <option value="">Organization Type</option>
                                                <option value="parent">Parent</option>
                                                <option value="dependent">Dependent</option>
                                            </select>
                                        </div>
                                    </div>
                                ) : props.reportName === REPORT_NAME.USER_ROLE ? (
                                    <div>
                                        <div>
                                            <div className="d-flex">
                                                <div> {props.reportName} Name</div>
                                                <div style={{ color: "red" }}>*</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.name}
                                                name="name"
                                                required
                                            />
                                        </div>
                                    </div>
                                ) : props.reportName === REPORT_NAME.USER_GROUP ? (
                                    <div>
                                        <div>
                                            <div className="d-flex">
                                                <div> {props.reportName} Name</div>
                                                <div style={{ color: "red" }}>*</div>
                                            </div>
                                            <input
                                                className="form-control form-control-sm w-100"
                                                type="text"
                                                aria-label=".form-control-sm example"
                                                onChange={handleChange}
                                                value={values.name}
                                                name="name"
                                                required
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="d-flex justify-content-center">
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        value={values.firstName}
                                                        name="firstName"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            // setName(e.target.value)
                                                        }}
                                                        className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.firstName ? 'is-invalid' : ''}`}
                                                        id="firstName"
                                                        aria-describedby="firstName"
                                                        placeholder="First Name"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <input
                                                        type="email"
                                                        value={values.email}
                                                        name="email"
                                                        onChange={handleChange}
                                                        className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.email ? 'is-invalid' : ''}`}
                                                        id="email"
                                                        aria-describedby="email"
                                                        placeholder="Email"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    {/* <input
                                                        type="text"
                                                        value={values.organizationName}
                                                        name="organizationName"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            setAddress(e.target.value)
                                                        }}
                                                        className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.organizationName ? 'is-invalid' : ''}`}
                                                        id="organizationName"
                                                        aria-describedby="organizationName"
                                                        placeholder="Organization Name"
                                                    /> */}
                                                    <select
                                                        className="form-select form-textbox"
                                                        aria-label=".form-select-sm example"
                                                        onChange={handleChange}
                                                        value={values.organizationId}
                                                        // value={orgId}
                                                        name="organizationId"
                                                    >
                                                        <option value="0">Select Organization</option>
                                                        {organizationData && organizationData.map((itemType: any, index: any) => {
                                                            return (
                                                                <option
                                                                    key={index.toString()}
                                                                    value={itemType.id}
                                                                >
                                                                    {itemType.id} - {itemType.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <select
                                                        className={`form-select form-textbox ${errors.group ? 'is-invalid' : ''}`}
                                                        aria-label="selectColumn"
                                                        onChange={handleChange}
                                                        aria-placeholder="Group"
                                                        value={values.group}
                                                        name="group"
                                                    >
                                                        <option>Group</option>
                                                        {allUserGroup && allUserGroup.map((userGroup: any, index: any) => {
                                                            return (
                                                                <option key={index} value={userGroup.id}>
                                                                    {userGroup.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        value={values.address}
                                                        name="address"
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            // setOrgName(e.target.value)
                                                        }}
                                                        className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.address ? 'is-invalid' : ''}`}
                                                        id="address"
                                                        aria-describedby="address"
                                                        placeholder="Address"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <select
                                                        className="form-select form-textbox"
                                                        // ${errors.country ? 'is-invalid' : ''}
                                                        aria-label="selectColumn"
                                                        onChange={handleSelectCountry}
                                                        aria-placeholder="Country"
                                                        name="country"
                                                        value={selectedCountry}
                                                    >
                                                        <option>Country</option>
                                                        {allCountries && allCountries.map((country: any, index: any) => {
                                                            return (
                                                                <option key={index} value={country.id}>
                                                                    {country.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <select
                                                        className="form-select form-textbox bg-secondary-light border-secondary-light"
                                                        // ${errors.state ? 'is-invalid' : ''}
                                                        aria-label="selectColumn"
                                                        onChange={handleChange}
                                                        aria-placeholder="State"
                                                        name="state"
                                                        value={values.state}
                                                    >
                                                        <option>State</option>
                                                        {allStates && allStates.map((state: any, index: any) => {
                                                            return (
                                                                <option key={index} value={state.id}>
                                                                    {state.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 ms-3">
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        value={values.lastName}
                                                        name="lastName"
                                                        onChange={handleChange}
                                                        className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.lastName ? 'is-invalid' : ''}`}
                                                        id="lastName"
                                                        aria-describedby="lastName"
                                                        placeholder="Last Name"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <input
                                                        type="number"
                                                        value={values.phoneNumber}
                                                        name="phoneNumber"
                                                        onChange={handleChange}
                                                        className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.phoneNumber ? 'is-invalid' : ''}`}
                                                        id="phoneNumber"
                                                        aria-describedby="phoneNumber"
                                                        placeholder="Mobile Number"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        value={values.linkedinRef}
                                                        name="linkedinRef"
                                                        onChange={handleChange}
                                                        className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.linkedinRef ? 'is-invalid' : ''}`}
                                                        id="linkedinRef"
                                                        aria-describedby="linkedinRef"
                                                        placeholder="Linkedin Ref"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <select
                                                        className={`form-select form-textbox ${errors.role ? 'is-invalid' : ''}`}
                                                        aria-label="selectColumn"
                                                        onChange={handleChange}
                                                        aria-placeholder="Role"
                                                        value={values.role}
                                                        name="role"
                                                    >
                                                        <option>Role</option>
                                                        {allUserRole && allUserRole.map((role: any, index: any) => {
                                                            return (
                                                                <option key={index} value={role.id}>
                                                                    {role.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <select
                                                        className={`form-select form-textbox ${errors.selectedPackage ? 'is-invalid' : ''}`}
                                                        aria-label="selectColumn"
                                                        onChange={handleChange}
                                                        aria-placeholder="Package"
                                                        value={values.selectedPackage}
                                                        name="selectedPackage"
                                                    >
                                                        <option>Pricing Plan</option>
                                                        {allPackagesData && allPackagesData.map((packageDetail: any, index: any) => {
                                                            return (
                                                                <option key={index} value={packageDetail.id}>
                                                                    {packageDetail.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <input
                                                        className="form-control form-textbox"
                                                        type="text"
                                                        aria-label=".form-control-sm example"
                                                        onChange={handleChange}
                                                        value={values.city}
                                                        name="city"
                                                        placeholder="City"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        value={values.pinCode}
                                                        name="pinCode"
                                                        onChange={handleChange}
                                                        className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.pinCode ? 'is-invalid' : ''}`}
                                                        id="pinCode"
                                                        aria-describedby="pinCode"
                                                        placeholder="Remark"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <div>
                                                <input type="checkbox"
                                                    id="termsAndConditionsCheckbox"
                                                    name="termsAndConditionsCheckbox"
                                                    checked={values.termsAndConditionsCheckbox}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="termsAndConditionsCheckbox" className="ms-1 text-secondary">I agree to the {" "}
                                                    <Link
                                                        to="https://passionit.com/index.php/terms-and-conditions/"
                                                        // onClick={() => localStorage.setItem("routeTo", "terms-and-conditions")}
                                                        target="_blank"
                                                        className="text-danger"
                                                    >
                                                        Terms and Conditions.
                                                    </Link>
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id="privacyPolicyCheckbox"
                                                    name="privacyPolicyCheckbox"
                                                    checked={values.privacyPolicyCheckbox}
                                                    // onChange={() => setFieldValue("privacyPolicyCheckbox", !values.privacyPolicyCheckbox)} 
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="privacyPolicyCheckbox" className="ms-1 text-secondary">I have read and agree to the {" "}
                                                    <Link
                                                        to="https://passionit.com/index.php/privacy-policy/"
                                                        // onClick={() => localStorage.setItem("routeTo", "privacy-policy")}
                                                        target="_blank"
                                                        className="text-danger"
                                                    >
                                                        Privacy Policy.
                                                    </Link>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <>
                                    {props.reportName == REPORT_NAME.ORGANIZATION_MASTER && (
                                        <div className="mt-3">
                                            <p>Icon</p>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                className="d-none me-3"
                                                onChange={(e: any) => handleIconChange(e, setFieldValue)} />
                                            <label htmlFor="fileInput" className="btn btn-danger">
                                                Upload
                                            </label>
                                            {imageBase64 && !newImage && (
                                                <img src={`data:image/png;base64,${imageBase64}`} alt="Current Logo" style={{ width: '50px', height: '50px', marginLeft: "5px" }} />
                                            )}
                                            {newImage && (
                                                <img src={newImage} alt="New Logo" style={{ width: '50px', height: '50px', marginLeft: "5px" }} />
                                            )}
                                            <div>
                                                <p>Allowed formats - JPG, PNG, SVG.</p>
                                            </div>
                                        </div>
                                    )}
                                </>
                                <div className="d-flex flex-row justify-content-center">
                                    <button
                                        type="submit"
                                        className={`btn btn-danger btn-block mt-3 mx-2 btn-width`}
                                    >
                                        {props.actionType === ACTION_TYPE.ADD_ACTION
                                            ? "Save"
                                            : "Update"}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block mt-3 mx-2 btn-width"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal >
        </>
    );

};

export default UserMasterReportData