export const userManagementBaseUrl = process.env.REACT_APP_USER_MANAGEMENT_BASE_URL

export const auditBaseUrl = process.env.REACT_APP_AUDIT_BASE_URL


export const assessmentAndAuditsUrl = process.env.REACT_APP_ASSESSMENT_AND_AUDITS_URL
export const riskManagementUrl = process.env.REACT_APP_RISK_MANAGEMENT_URL
export const driftAndAutoAssessment = process.env.REACT_APP_DRIFT_AND_AUTO_ASSESSMENT

export const assessmentAndAuditsBaseUrl = process.env.REACT_APP_ASSESSMENT_AND_AUDITS_BASE__URL
export const riskManagementBaseUrl = process.env.REACT_APP_RISK_MANAGEMENT_BASE_URL

export const evidenceNavigateUrl = process.env.REACT_APP_EVIDENCE_NAVIGATE_URL

export const cascadBaseUrl = process.env.REACT_APP_CASCAD_URL

export const shapLimeBaseUrl = process.env.REACT_APP_SHAPLIME_URL

export const documentManagementUrls = {
  parameter: `${riskManagementUrl}parameter`,
  parameterCategory: `${riskManagementUrl}par`
}

export const assessmentAndAudits = {
  getAllCompany: "companyget/api",
}

export const userManagement = {
  loginController: {
    login: "/login",
    logout: "/logout",
  },
  permissionController: {
    getAllPermissions: "/permissions/findAllPermissions",
  },
  roleController: {
    getRoles: "/roles",
    addRole: "/roles",
    findRoleById: "/roles/",
    updateRole: "/roles/",
    deleteRole: "/roles/",
  },
  userController: {
    addUser: "/users",
    findUserById: "/users",
    updateUser: "/users",
    deleteUser: "/users",
    getUsers: "/users",
    forgotPassword: "/users/forgotPassword",
    resetPassword: "/users/resetPassword",
    uploadUsers: "/users/upload",
    findUsername: "/users/username",
  },
  userRoleController: {
    getUserRoleById: "/roles",
    updateUserRole: "/roles/updateUserRole",
    deleteUserRoleById: "/roles/deleteUserRole",
    getAllUserRole: "/roles/findAllUserRole",
    saveUserRole: "/roles",
  },
  userGroupController: {
    getUserGroupById: "/userGroup/findUserGroup",
    updateUserGroup: "/userGroup/updateUserGroup",
    deleteUserGroupById: "/userGroup/deleteUserGroup",
    getAllUserGroup: "/userGroup/findAllUserGroup",
    saveUserGroup: "/userGroup/saveUserGroup",
  },
  usersController: {
    getUserById: "/users",
    updateUser: "/users",
    deleteUserById: "/users",
    getAllUser: "/users",
    saveUser: "/users",
    resetPassword: "/users/resetPassword",
    getAllUsersWithSearch: "/users/getAllUsersWithSearch",
  },
  countryController: {
    getAllCountries: "/country/getAllCountries",
    saveCountry: "/country/saveCountry",
    updateCountry: "/country/updateCountry",
    deleteCountry: "/country/deleteCountry",
    getCountryById: "/country/getCountryById",
    getAllCountriesWithSearch: "/country/getAllCountriesWithSearch",
  },
  cityController: {
    getAllCities: "/city/getAllCities",
  },
  stateController: {
    getState: "/state/getState",
    saveState: "/state/saveState",
    updateState: "/state/updateState",
    deleteState: "/state/deleteState",
    getStateById: "/state/getStateById",
    getAllStates: "/state/getAllStates",
    getAllStatesWithSearch: "/state/getAllStatesWithSearch",
  },
  auditLogController: {
    saveAuditLog: "auditLog/save",
    getAuditLog: "auditLog/find"
  },
  faqsController: {
    getAllFAQs: "/faq",
    saveFAQ: "/faq/saveFAQ",
    updateFAQ: "/faq/updateFAQ",
    deleteFAQ: "/faq/deleteFAQ",
    getFAQById: "/faq",
    getAllFAQsWithSearch: "/faq/getAllFAQsWithSearch",
  },
  sectionMasterController: {
    getAllSectionMaster: "/sectionMaster",
    saveSectionMaster: "/sectionMaster/saveSectionMaster",
    updateSectionMaster: "/sectionMaster/updateSectionMaster",
    deleteSectionMaster: "/sectionMaster/deleteSectionMaster",
    getSectionMasterById: "/sectionMaster",
    getAllSectionMasterWithSearch: "/sectionMaster/getAllSectionMasterWithSearch",
    findAllSectionMaster: "/sectionMaster/findAllSectionMaster"
  },
  documentCategoryController: {
    getAllDocumentCategory: "/documentCategory",
    saveDocumentCategory: "/documentCategory/saveDocumentCategory",
    updateDocumentCategory: "/documentCategory/updateDocumentCategory",
    deleteDocumentCategory: "/documentCategory/deleteDocumentCategory",
    getDocumentCategoryById: "/documentCategory",
    getAllDocumentCategoryWithSearch: "/documentCategory/getAllDocumentCategoryWithSearch",
    findAllDocumentCategory: "/documentCategory/findAllDocumentCategory"
  },
  documentMasterController: {
    getAllDocumentMaster: "/documentMaster",
    saveDocumentMaster: "/documentMaster/saveDocumentMaster",
    updateDocumentMaster: "/documentMaster/updateDocumentMaster",
    deleteDocumentMaster: "/documentMaster/deleteDocumentMaster",
    getDocumentMasterById: "/documentMaster",
    getAllDocumentMasterWithSearch: "/documentMaster/getAllDocumentMasterWithSearch",
    findAllDocumentMaster: "/documentMaster/findAllDocumentMaster"
  },
  createDoucmentController: {
    createDocument: "createDocument/generate",
    getAllDocumentRecords: "createDocument",
    getDocumentRecordById: "createDocument/findDocumentRecordById",
    updateDocumentRecord: "createDocument/updateDocumentRecord",
    deleteDocumentRecord: "/createDocument/deleteDocumentRecord",
    getDocumentRecordWithSearch: "/createDocument/getAllDocumentRecordWithSearch",
  },
  packageMasterController: {
    findAllPackages: "/package/getAllPackages",
    getAllPackages: "/package",
    savePackage: "/package/savePackage",
    updatePackage: "/package/updatePackage",
    deletePackage: "/package/deletePackage",
    getPakcageById: "/package",
    getAllPackagesWithSearch: "/package/getAllPackagesWithSearch"
  },
};

export const audit = {
  dimensionMasterController: {
    getAllDimensionMaster: "/dimensionMaster",
    saveDimensionMaster: "/dimensionMaster/saveDimensionMaster",
    updateDimensionMaster: "/dimensionMaster/updateDimensionMaster",
    deleteDimensionMaster: "/dimensionMaster/deleteDimensionMaster",
    getDimensionMasterById: "/dimensionMaster",
    getAllDimensionMasterWithSearch: "/dimensionMaster/getAllDimensionMasterWithSearch",
    findAllDimensionMaster: "/dimensionMaster/getAllDimensionMaster"
  },
  processMasterController: {
    getAllProcessMaster: "/processMaster",
    saveProcessMaster: "/processMaster/saveProcessMaster",
    updateProcessMaster: "/processMaster/updateProcessMaster",
    deleteProcessMaster: "/processMaster/deleteProcessMaster",
    getProcessMasterById: "/processMaster",
    getAllProcessMasterWithSearch: "/processMaster/getAllProcessMasterWithSearch",
    findAllProcessMaster: "/processMaster/getAllProcessMaster"
  },
  auditController: {
    getAllAudits: "/audit",
    saveAudit: "/audit/saveAudit",
    updateAudit: "/audit/updateAudit",
    deleteAudit: "/audit/deleteAudit",
    getAuditById: "/audit",
    getAuditBySearch: "/audit/getAllAuditWithSearch",
    findAllAudits: "/audit/getAllAudit",
    getAuditsBasedOnUser: "/audit/audit_based_on_user",
    getAuditsByOrganization: "/audit/audit_based_on_org_depOrg",
    sendAuditDocument: "/audit/sendAuditDocument"
  },
  auditFrameworkController: {
    getAllAuditFramework: "/auditFramework",
    saveAuditFramework: "/auditFramework/saveAuditFramework",
    updateAuditFramework: "/auditFramework/updateAuditFramework",
    deleteAuditFramework: "/auditFramework/deleteAuditFramework",
    getAuditFrameworkById: "/auditFramework",
    getAuditFrameworkBySearch: "/auditFramework/getAllAuditFrameworkWithSearch",
    findAllAuditFramework: "/auditFramework/getAllAuditFramework",
    uploadXlsx: "/auditFramework/uploadXlsx",
    getAllAuditFrameworkName: "/auditFramework/getAllAuditFrameworkName"
  },
  groupMasterController: {
    getAllGroupMaster: "/groupMaster",
    saveGroupMaster: "/groupMaster/saveGroupMaster",
    updateGroupMaster: "/groupMaster/updateGroupMaster",
    deleteGroupMaster: "/groupMaster/deleteGroupMaster",
    getGroupMasterById: "/groupMaster",
    getAllGroupMasterBySearch: "/groupMaster/getAllGroupMasterWithSearch",
    findAllGroupMaster: "/groupMaster/getAllGroupMaster"
  },
  generatePdfContorller: {
    generatePdf: "/pdf/generate_pdf",
    generateAuditDocument: "/pdf/generateAuditDocument",
  },
  organizationMasterController: {
    getAllOrganizationMaster: "/organizationMaster",
    saveOrganizationMaster: "/organizationMaster/saveOrganizationMaster",
    updateOrganizationMaster: "/organizationMaster/updateOrganizationMaster",
    deleteOrganizationMaster: "/organizationMaster/deleteOrganizationMaster",
    getOrganizationMasterById: "/organizationMaster",
    getAllOrganizationMasterBySearch: "/organizationMaster/getAllOrganizationMasterBySearch",
    findAllOrganizationMaster: "/organizationMaster/getAllOrganizationMaster",
    getAllDepOrgCode: "/organizationMaster/getAllDepOrgCode",
  },
  excelController: {
    downloadCertificate: "/excel/downloadCertificate",
    authenticateCertificate: "/excel/verifyQRCode"
  }
}