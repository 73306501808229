import { useEffect, useState } from "react";
import "./Home/home.scss"
import { getLocalStorageData } from "../utils/LocalStorageService";
import { useDispatch } from "react-redux";
import { useFindAllOrganizationMasterMutation, useGetAllDepOrgCodeMutation } from "../redux-toolkit/services/organizationMasterService";
import { useFindAllAuditFrameworkMutation, useGetAllAuditFrameworkNameMutation } from "../redux-toolkit/services/auditFrameworkService";
import { useFindAllGroupMasterMutation } from "../redux-toolkit/services/groupMasterService";
import { useFindAllProcessMasterMutation } from "../redux-toolkit/services/processMasterService";
import { useFindAllDimensionMasterMutation } from "../redux-toolkit/services/dimensionMasterService";
import { useGetAuditBySearchMutation, useGetAuditsBasedOnOrganizationMutation, useGetAuditsBasedOnUserMutation } from "../redux-toolkit/services/auditService";
import { useAppSelector } from "../redux-toolkit/hooks";
import { allOrganizationMasterDetails, getAllDependentOrgCode, setAllDependentOrgCode, setAllOrganizationMaster } from "../redux-toolkit/slices/organizationMasterSlice";
import { allAuditFrameworkDetails, allAuditFrameworkNameDetails, setAllAuditFramework, setAllAuditFrameworkName } from "../redux-toolkit/slices/auditFrameworkSlice";
import { allGroupMasterDetails, setAllGroupMaster } from "../redux-toolkit/slices/groupMasterSlice";
import { allProcessMasterDetails, setAllProcessMaster } from "../redux-toolkit/slices/processMasterSlice";
import { allDimensionMasterDetails, setAllDimensionMaster } from "../redux-toolkit/slices/dimensionMasterSlice";
import { getAuditsBasedOnOrgTotalRecords, getAuditsByUserTotalRecords, getAuditTotalRecords, setAllAuditBySearch, setAuditsByUser } from "../redux-toolkit/slices/auditSlice";
import { BsFileEarmarkText } from "react-icons/bs";
import { TbStars } from "react-icons/tb";
import { OVERALL_AUDIT_STATUS, TABLE_COLUMN } from "../constant/AppConstant";
import { alertMessage } from "../utils/AlertService";
import { fDate } from "../utils/util";
import Loading from "./Loading";
import { DatePicker, Space, Table } from "antd";
import ReportDetails from "./Home/ReportDetails";
import AuditRating from "./Home/AuditRating";

const { RangePicker } = DatePicker;

type Props = {};

const Home = (props: Props) => {

    const localUserObj = getLocalStorageData("localUser");
    const dispatch = useDispatch();

    //states
    const [dataSource, setDataSource]: any = useState([]);
    const [currentpage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [columData, setColumnData]: any = useState();
    const [show, setShow] = useState(false);
    const [showRating, setShowRating] = useState(false);
    const [selectedRow, setSelectedRow]: any = useState([]);
    const [auditData, setAuditData]: any = useState();
    const [searchFields, setSearchFields] = useState({
        auditorName: "",
        organizationName: "",
        auditorCompany: "",
        auditeeName: "",
        auditorEmail: "",
        auditFrameworkName: "",
        groupMaster: "",
        dimension: "",
        process: "",
        stakeholder: "",
        auditType: "",
        auditStatus: "",
    });

    //getData
    const [getAllCompanies, allCompaniesResponse] = useFindAllOrganizationMasterMutation();
    const [findAllAuditFrameworkName, findAllAuditFrameworkNameResponse] = useGetAllAuditFrameworkNameMutation();
    const [findAllGroupMaster, findAllGroupMasterResponse] = useFindAllGroupMasterMutation();
    const [findAllProcessMaster, findAllProcessMasterResponse] = useFindAllProcessMasterMutation();
    const [findAllDimensionMaster, findAllDimensionMasterResponse] = useFindAllDimensionMasterMutation();
    const [getAuditsBasedOnOrganization, auditsBasedOnOrganizationResponse] = useGetAuditsBasedOnOrganizationMutation();
    const [getAuditsBySearch, auditsBySearchResponse] = useGetAuditBySearchMutation();
    const [allDependentOrgCode, allDependentOrgCodeResponse] = useGetAllDepOrgCodeMutation();

    //selector
    const organizationData: any = useAppSelector(allOrganizationMasterDetails);
    const auditFrameworkData: any = useAppSelector(allAuditFrameworkNameDetails);
    const allGroupMasterData: any = useAppSelector(allGroupMasterDetails);
    const allProcessMasters: any = useAppSelector(allProcessMasterDetails);
    const allDimensionMasters: any = useAppSelector(allDimensionMasterDetails);
    const getAuditsBasedOnOrgTotal: any = useAppSelector(getAuditsBasedOnOrgTotalRecords);
    const auditTotalRecords: any = useAppSelector(getAuditTotalRecords);
    const allDepOrgCodeData: any = useAppSelector(getAllDependentOrgCode);

    useEffect(() => {
        getAllCompanies({
            token: localUserObj?.token,
        });
        findAllAuditFrameworkName({
            token: localUserObj?.token,
        });
        findAllDimensionMaster({
            token: localUserObj?.token,
        });
        findAllProcessMaster({
            token: localUserObj?.token,
        });
        findAllGroupMaster({
            token: localUserObj?.token,
        });
        allDependentOrgCode({
            token: localUserObj?.token
        })

        // getAuditsBasedOnOrganization({
        //     token: localUserObj.token,
        //     payload: {
        //         offset: pageSize,
        //         pageNumber: currentpage - 1,
        //         // orderBy: "asc",
        //         searchBy: {
        //             depOrganizationName: localUserObj.organization
        //         }
        //     }
        // })
        getAuditsBySearch({
            token: localUserObj?.token,
            payload: {
                offset: pageSize,
                pageNumber: currentpage - 1,
                searchBy: {
                    dependentOrgCode: localUserObj.organization,
                }
            }
        })
        // getAuditsBySearch({
        //     token: localUserObj?.token,
        //     payload: {
        //         offset: 100,
        //         pageNumber: 0,
        //         searchBy: searchPayload
        //     },
        // });
    }, [])

    const paginationChange = (page: number, pageSize: number) => {
        // getAuditsBasedOnOrganization({
        //     token: localUserObj.token,
        //     payload: {
        //         offset: pageSize,
        //         pageNumber: currentpage - 1,
        //         // orderBy: "asc",
        //         searchBy: {
        //             depOrganizationName: localUserObj.organization
        //         }
        //     }
        // });
        getAuditsBySearch({
            token: localUserObj?.token,
            payload: {
                offset: pageSize,
                pageNumber: currentpage - 1,
                searchBy: {
                    dependentOrgCode: localUserObj.organization,
                }
            }
        })
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const handleChange = (e: any) => {

        let { name, value } = e.target;

        if (name === "organizationName") {
            const org = organizationData && organizationData
                .find((item: any) => {
                    return parseInt(item.id) === parseInt(value);
                });

            value = org?.name ? org?.name : ""
        }
        // else if (name === "auditFrameworkName") {
        //     const auditFramework = auditFrameworkData && auditFrameworkData
        //         .find((item: any) => {
        //             return parseInt(item.id) === parseInt(value);
        //         });

        //     value = auditFramework.name
        // }

        setSearchFields((prevFields: any) => {
            const updatedFields = {
                ...prevFields,
                // Handle date range case
                ...(name === 'dateRange'
                    ? { fromDate: value.fromDate, toDate: value.toDate }
                    : { [name]: value }  // For other search fields
                ),
            };

            // Call handleSearch with the updated searchFields after a debounce
            setTimeout(() => {
                handleSearch(updatedFields);
            }, 1000);

            return updatedFields;  // Return updated state
        });

        // Update searchFields
        // const updatedFields: any = searchFields.map((field: any) => field.name === name ? { name, value } : field);
        // if (!updatedFields.some((field: any) => field.name === name)) {
        //     updatedFields.push({ name, value });
        // }

        // setSearchFields(updatedFields)

        // setTimeout(() => {
        //     handleSearch(updatedFields)
        // }, 300);
    }

    const handleSearch = (searchPayload: any) => {

        getAuditsBySearch({
            token: localUserObj?.token,
            payload: { offset: 100, pageNumber: 0, searchBy: searchPayload },
        });
    }

    const handleOpenReport = (record: any) => {
        setSelectedRow(record);
        // setAuditData()
        setShow(true);
    };

    const handleOpenRating = (record: any) => {
        setSelectedRow(record);
        // setAuditData()
        setShowRating(true);
    };

    useEffect(() => {
        const tempColumn: any = [];
        const tempAuditReport: any = {
            title: "Audit Report",
            dataIndex: "auditReport",
            key: "auditReport",
            fixed: "right",
            render: (index: any, record: any) => {
                return (
                    // for delete switch button
                    <div className="d-flex justify-content-between">
                        <div
                            className="px-2 pe-auto"
                            onClick={() => {
                                handleOpenReport(record);
                            }}
                        >
                            <BsFileEarmarkText className="text-danger" size={18} />
                        </div>
                    </div>
                );
            },
        };

        const tempRating: any = {
            title: "Rating",
            dataIndex: "rating",
            key: "rating",
            fixed: "right",
            render: (index: any, record: any) => {
                return (
                    // for delete switch button
                    <div className="d-flex justify-content-between">
                        <div
                            className="px-2 pe-auto"
                            onClick={() => {
                                handleOpenRating(record);
                            }}
                        >
                            <TbStars className="text-danger" size={18} />
                        </div>
                    </div>
                );
            },
        };

        TABLE_COLUMN.map((item: any) => {
            tempColumn.push(item);
        });
        tempColumn.push(tempAuditReport);
        tempColumn.push(tempRating);
        setColumnData(tempColumn);
    }, []);

    useEffect(() => {
        if (allCompaniesResponse.isSuccess) {
            dispatch(setAllOrganizationMaster(allCompaniesResponse));
        } else if (allCompaniesResponse.isError) {
            alertMessage("Error fetching companies", "error");
        }
    }, [allCompaniesResponse.isSuccess, allCompaniesResponse.isError]);

    useEffect(() => {
        if (findAllAuditFrameworkNameResponse.isSuccess) {
            dispatch(setAllAuditFrameworkName(findAllAuditFrameworkNameResponse));
        } else if (findAllAuditFrameworkNameResponse.isError) {
            alertMessage("Error while fetching audit framewrok", "error");
        }
    }, [findAllAuditFrameworkNameResponse.isSuccess, findAllAuditFrameworkNameResponse.isError]);

    useEffect(() => {
        if (findAllGroupMasterResponse.isSuccess) {
            dispatch(setAllGroupMaster(findAllGroupMasterResponse))
        } else if (findAllGroupMasterResponse.isError) {
            alertMessage("Error while fetching group master", "error");
        }
    }, [findAllGroupMasterResponse.isSuccess, findAllGroupMasterResponse.isError]);

    useEffect(() => {
        if (findAllDimensionMasterResponse.isSuccess) {
            dispatch(setAllDimensionMaster(findAllDimensionMasterResponse))
        } else if (findAllDimensionMasterResponse.isError) {
            alertMessage("Error while fetching dimension masters", "error");
        }
    }, [findAllDimensionMasterResponse.isSuccess, findAllDimensionMasterResponse.isError]);

    useEffect(() => {
        if (findAllProcessMasterResponse.isSuccess) {
            dispatch(setAllProcessMaster(findAllProcessMasterResponse))
        } else if (findAllProcessMasterResponse.isError) {
            alertMessage("Error while fetching process masters", "error");
        }
    }, [findAllProcessMasterResponse.isSuccess, findAllProcessMasterResponse.isError]);

    useEffect(() => {
        if (auditsBasedOnOrganizationResponse.isSuccess) {
            dispatch(setAuditsByUser(auditsBasedOnOrganizationResponse))
            setAuditData(auditsBasedOnOrganizationResponse.data.data)
            const dataSource = transformData(auditsBasedOnOrganizationResponse.data.data);
            setDataSource(dataSource)
        } else if (auditsBasedOnOrganizationResponse.isError) {
            alertMessage("Error while fetching audits data", "error");
        }
    }, [auditsBasedOnOrganizationResponse.isSuccess, auditsBasedOnOrganizationResponse.isError]);

    useEffect(() => {
        if (auditsBySearchResponse.isSuccess) {
            dispatch(setAllAuditBySearch(auditsBySearchResponse));
            setAuditData(auditsBySearchResponse.data.data)
            const dataSource = transformData(auditsBySearchResponse.data.data);
            setDataSource(dataSource);
        } else if (auditsBySearchResponse.isError) {
            alertMessage("Error while searching", "error");
        }
    }, [auditsBySearchResponse.isSuccess, auditsBySearchResponse.isError]);

    useEffect(() => {
        if (allDependentOrgCodeResponse.isSuccess) {
            dispatch(setAllDependentOrgCode(allDependentOrgCodeResponse));
        } else if (allDependentOrgCodeResponse.isError) {
            alertMessage("Error while fetching dependent organizations", "error");
        }
    }, [allDependentOrgCodeResponse.isSuccess, allDependentOrgCodeResponse.isError]);

    function transformData(rawData: any) {

        // Create a copy of rawData to avoid modifying the original array
        const sortedData = [...rawData];

        // Sort the data first by dependentOrgCode and then by organizationName
        sortedData.sort((a, b) => {
            // Sort by dependentOrgCode first
            if (a.dependentOrgCode < b.dependentOrgCode) return -1;
            if (a.dependentOrgCode > b.dependentOrgCode) return 1;

            // If dependentOrgCode is the same, sort by organizationName
            if (a.organizationName < b.organizationName) return -1;
            if (a.organizationName > b.organizationName) return 1;

            return 0;
        });

        console.log(sortedData)

        return sortedData.map((item: any) => {
            // Extract relevant fields
            const {
                id,
                auditorCompanyName,
                auditorName,
                organizationName,
                dependentOrgCode,
                createdDate,
                validityPeriodInDays,
                auditReport,
            } = item;

            // Calculate Next Audit Date and Expiry Date
            const nextAuditDate = calculateNextAuditDate(createdDate, validityPeriodInDays !== null ? parseInt(validityPeriodInDays) : 0);
            const expiryDate = calculateExpiryDate(createdDate, validityPeriodInDays !== null ? parseInt(validityPeriodInDays) : 0);

            return {
                auditId: id,
                auditorCompanyName: auditorCompanyName || "-",
                auditorName: auditorName || "-",
                organizationName: organizationName || "-",
                dependentOrgCode: dependentOrgCode || "-",
                createdDate: new Date(createdDate).toISOString().split('T')[0],
                nextAuditDate,
                validityPeriodInDays,
                expiryDate,
                auditReport: auditReport || "-",
            };
        });
    }

    function calculateNextAuditDate(createdDate: any, validityPeriodInDays: any) {
        const date = new Date(createdDate);
        date.setDate(date.getDate() + validityPeriodInDays);
        // return date.toISOString().split('T')[0];
        return fDate(new Date(date));
    }

    function calculateExpiryDate(createdDate: any, validityPeriodInDays: any) {
        const date = new Date(createdDate);
        // If validityPeriodInDays is 0, expiry date will be the same as createdDate
        date.setDate(date.getDate() + (validityPeriodInDays > 0 ? validityPeriodInDays - 1 : 0));
        return fDate(new Date(date));
    }


    return (
        <div>
            {auditsBasedOnOrganizationResponse.isLoading ||
                allCompaniesResponse.isLoading ||
                findAllAuditFrameworkNameResponse.isLoading ||
                findAllGroupMasterResponse.isLoading ||
                findAllProcessMasterResponse.isLoading ||
                findAllDimensionMasterResponse.isLoading ||
                auditsBySearchResponse.isLoading ||
                allDependentOrgCodeResponse.isLoading ? (
                <Loading />
            ) : (
                ""
            )}
            <div className="fw-bold fs-4 mt-2">
                Client Subgroup Audit Traceability
            </div>
            <div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="mt-3">
                            <div className="d-flex">
                                <div>Auditor Name</div>
                            </div>
                            <input
                                className="form-control form-control-sm w-100"
                                type="text"
                                aria-label=".form-control-sm example"
                                onChange={handleChange}
                                // value={values.auditorName}
                                name="auditorName"
                            // required
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Organization</div>
                            </div>
                            <div className="pe-2">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm example"
                                    onChange={handleChange}
                                    // value={values.organizationId}
                                    name="organizationName"
                                >
                                    <option value="">Select Organization</option>
                                    {organizationData && organizationData.map((itemType: any, index: any) => {
                                        return (
                                            <option
                                                key={index.toString()}
                                                value={itemType.id}
                                            >
                                                {itemType.id} - {itemType.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Dependent Organization</div>
                            </div>
                            <div className="pe-2">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm example"
                                    onChange={handleChange}
                                    // value={values.organizationId}
                                    name="dependentOrgCode"
                                >
                                    <option value="">Select Dependent Organization</option>
                                    {allDepOrgCodeData && allDepOrgCodeData.map((itemType: any, index: any) => {
                                        return (
                                            <option
                                                key={index.toString()}
                                                value={itemType.dependentOrgCode}
                                            >
                                                {itemType.dependentOrgCode}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="mt-3">
                            <div className="d-flex">
                                <div>Auditor Company</div>
                            </div>
                            <input
                                className="form-control form-control-sm w-100"
                                type="text"
                                aria-label=".form-control-sm example"
                                onChange={handleChange}
                                // value={auditorCompanyName}
                                name="auditorCompany"
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mt-3">
                            <div className="d-flex">
                                <div>Auditee Name</div>
                            </div>
                            <input
                                className="form-control form-control-sm w-100"
                                type="text"
                                aria-label=".form-control-sm example"
                                onChange={handleChange}
                                // value={values.auditorName}
                                name="auditeeName"
                            // required
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mt-3">
                            <div className="d-flex">
                                <div>Auditor Email</div>
                            </div>
                            <input
                                className="form-control form-control-sm w-100"
                                type="text"
                                aria-label=".form-control-sm example"
                                onChange={handleChange}
                                // value={auditorEmail}
                                name="auditorEmail"
                            />
                        </div>
                    </div>

                </div>
                <div className="row mb-4">
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Audit Framework</div>
                            </div>
                            <div className="pe-3">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm"
                                    onChange={handleChange}
                                    // onChange={(e: any) => {
                                    //     handleChange(e);
                                    //     console.log(e)
                                    //     handleAuditFrameworkChange(e.target.value);
                                    //     setAuditFramework(e.target.value)
                                    // }}
                                    name="auditFrameworkName"
                                // value={values.auditFramework}
                                >
                                    <option value="0">
                                        Select audit framework
                                    </option>
                                    {auditFrameworkData && auditFrameworkData.map((framework: any, index: any) => (
                                        <option key={index.toString()} value={framework.name}>
                                            {framework.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Group Master</div>
                            </div>
                            <div className="pe-3">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm example"
                                    onChange={handleChange}
                                    name="groupMaster"
                                // value={values.groupMaster}
                                >
                                    <option value="">
                                        Select Group
                                    </option>
                                    {allGroupMasterData &&
                                        allGroupMasterData.map((itemType: any, index: any) => {
                                            return (
                                                <option
                                                    key={index.toString()}
                                                    value={itemType.id}
                                                >
                                                    {itemType.id} - {itemType.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Dimension</div>
                            </div>
                            <div className="pe-3">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm example"
                                    onChange={handleChange}
                                    name="dimension"
                                // value={values.dimension}
                                >
                                    <option value="">
                                        Select Dimension
                                    </option>
                                    {allDimensionMasters &&
                                        allDimensionMasters.map((itemType: any, index: any) => {
                                            return (
                                                <option
                                                    key={index.toString()}
                                                    value={itemType.id}
                                                >
                                                    {itemType.id} - {itemType.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row mb-4">
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Process</div>
                            </div>
                            <div className="pe-3">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm example"
                                    onChange={handleChange}
                                    name="process"
                                // value={values.process}
                                >
                                    <option value="">
                                        Select Process
                                    </option>
                                    {allProcessMasters &&
                                        allProcessMasters.map((itemType: any, index: any) => {
                                            return (
                                                <option
                                                    key={index.toString()}
                                                    value={itemType.id}
                                                >
                                                    {itemType.id} - {itemType.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Audit Type</div>
                            </div>
                            <div className="pe-3">
                                <input
                                    className="form-control form-control-sm w-100"
                                    type="text"
                                    aria-label=".form-control-sm example"
                                    onChange={handleChange}
                                    // value={values.auditorName}
                                    name="auditType"
                                // required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100 mt-3">
                            <div className="d-flex">
                                <div>Stakeholder</div>
                            </div>
                            <div className="pe-3">
                                <input
                                    className="form-control form-control-sm w-100"
                                    type="text"
                                    aria-label=".form-control-sm example"
                                    onChange={handleChange}
                                    // value={values.auditorName}
                                    name="stakeholder"
                                // required
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row mb-4">
                    <div className="col-md-4">
                        <div className="w-100">
                            <div className="d-flex">
                                <div>Audit Status</div>
                            </div>
                            <div className="pe-3">
                                <select
                                    className="form-select form-select-sm w-100"
                                    aria-label=".form-select-sm example"
                                    name="auditStatus"
                                    onChange={handleChange}
                                // value={auditStatusMap.find((entry: any) => entry.recordId === record.id)?.auditStatus || ""}
                                // onChange={(e) => handleAuditStatusChange(record, e.target.value)}
                                >
                                    <option value="">Select status</option>
                                    {OVERALL_AUDIT_STATUS.map((itemType: any) => {
                                        return (
                                            <option
                                                key={itemType.id}
                                                value={itemType.name}
                                            >
                                                {itemType.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="w-100">
                            <div className="d-flex">
                                <div>Audit from and To</div>
                            </div>
                            <Space direction="vertical" size={12}>
                                <RangePicker
                                    name="dateRange"
                                    onChange={(dates, dateStrings) => {
                                        // console.log('Selected Dates: ', dates);  // Raw date objects
                                        // console.log('Formatted Dates: ', dateStrings);  // Formatted date strings (e.g., "YYYY-MM-DD")

                                        // Update the state for fromDate and toDate
                                        handleChange({
                                            target: {
                                                name: "dateRange",
                                                value: {
                                                    fromDate: dateStrings[0],  // Start date
                                                    toDate: dateStrings[1],    // End date
                                                }
                                            }
                                        });
                                    }}
                                // disabledDate={(current: any) => {
                                //     return current && isAfter(current, endOfDay(new Date()));
                                // }} // for disabling past dates
                                />
                            </Space>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <Table
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource}
                    columns={columData}
                    pagination={{
                        total: auditTotalRecords !== null ? auditTotalRecords : dataSource.length,
                        // hideOnSinglePage: true,
                        responsive: true,
                        current: currentpage,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 20, 30],
                        onChange: (page, pageSize) => {
                            paginationChange(page, pageSize);
                        },
                        pageSize: pageSize,
                    }}
                // rowKey={(record) => `${record.dimensionId}_${record.processId}`}
                />
            </div>
            {show && (
                <ReportDetails
                    show={show}
                    setShow={setShow}
                    data={selectedRow}
                    auditData={auditData}
                />
            )}
            {showRating && (
                <AuditRating
                    show={showRating}
                    setShow={setShowRating}
                    data={selectedRow}
                    auditData={auditData}
                />
            )}
        </div>
    );
};

export default Home;