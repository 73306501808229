import { Button, Dropdown, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import logo from "../../assets/logo/auditLogo.png";
import { BsBoxArrowDown } from "react-icons/bs";
import { TABLE_COLUMN_REPORT_DETAILS } from "../../constant/AppConstant";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { useGenerateAuditDocumentMutation } from "../../redux-toolkit/services/pdfService";
import { alertMessage } from "../../utils/AlertService";
import { setPdfBlob, setPdfURL } from "../../redux-toolkit/slices/pdfSlice";
import { MdOutlineAttachEmail } from "react-icons/md";
import { Form, Formik } from "formik";
import { useSendAuditDocumentMutation } from "../../redux-toolkit/services/auditService";
import Loading from "../Loading";
import ReactQuill from "react-quill";

type Props = {
    show: boolean;
    setShow: any;
    data: any;
    auditData: any;
};


const ReportDetails = (props: Props) => {

    const localUserObj: any = getLocalStorageData("localUser");
    const dispatch = useDispatch();

    //states
    const [dataSource, setDataSource] = useState([]);
    const [openEmailModal, setOpenEmailModal] = useState(false);

    //get mutation
    const [generateDocument, generateDocumentResponse] = useGenerateAuditDocumentMutation();
    const [sendAuditDocument, sendAuditDocumentResponse] = useSendAuditDocumentMutation();

    // console.log(props.data)
    // console.log(props.auditData)

    const handleClose = () => {
        props.setShow(false);
    };

    const handleEmailClose = () => {
        setOpenEmailModal(false);
    }

    const handleDownload = (value: any) => {
        if (value === "pdf") {
            // props.data.auditId
            generateDocument({
                token: localUserObj.token,
                type: value,
                auditId: parseInt(props.data.auditId)
            })

        } else if (value === "xlsx") {
            generateDocument({
                token: localUserObj.token,
                type: value,
                auditId: parseInt(props.data.auditId)
            })
        }
    };

    useEffect(() => {
        let temp: any = [];
        if (props.auditData) {
            (props.auditData)?.map((audit: any) => {
                if (audit.id === props.data.auditId) {
                    audit.auditFrameworkAuditMappings.map((item: any) => {
                        temp.push(item)
                    })
                }
            })

            setDataSource(temp)
        }

    }, [props.auditData])

    useEffect(() => {
        if (generateDocumentResponse.isSuccess) {
            alertMessage("Document generated successfully", "success");

            // const url = window.URL.createObjectURL(generateDocumentResponse.data);
            // dispatch(setPdfURL(url));
            // dispatch(setPdfBlob(generateDocumentResponse.data));

        } else if (generateDocumentResponse.isError) {
            alertMessage("Error while generating document", "error");
        }
    }, [generateDocumentResponse.isSuccess, generateDocumentResponse.isError]);

    useEffect(() => {
        if (sendAuditDocumentResponse.isSuccess) {
            setOpenEmailModal(false);
            alertMessage("Document sent successfully", "success");
        } else if (sendAuditDocumentResponse.isError) {
            alertMessage("Error while sending document", "error");
        }
    }, [sendAuditDocumentResponse.isSuccess, sendAuditDocumentResponse.isError]);

    const handleSubmitForm = async (values: any) => {
        if (values.type !== "") {
            sendAuditDocument({
                token: localUserObj.token,
                payload: {
                    email: values.email,
                    type: values.type,
                    auditId: parseInt(props.data.auditId),
                    subject: values.subject,
                    body: values.body
                }
            })
        } else {
            alertMessage("Please select document type", "error")
        }
    };

    return (
        <>
            <Modal
                show={props.show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
                // size={"xl"}
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <>
                        <Button variant="danger me-3">
                            <MdOutlineAttachEmail size={20} className="text-white" onClick={() => setOpenEmailModal(true)} />
                        </Button>
                        <Dropdown>
                            <Dropdown.Toggle variant="danger" id="dropdown-basic">
                                <BsBoxArrowDown size={20} className="text-white" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleDownload("pdf")}>Pdf</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleDownload("xlsx")}>Xlsx</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                </Modal.Header>
                <Modal.Body>
                    <div id="pdf-content">
                        <div className="w-100 d-flex align-items-center">
                            {/* Logo on the left */}
                            <div>
                                <img src={logo} width="70" height="50" alt="logo" />
                            </div>

                            {/* Spacer div to push title to the center */}
                            <div className="flex-grow-1 text-center">
                                <p className="fw-bold text-danger m-0" style={{ fontSize: "1rem" }}>
                                    Audit Details
                                </p>
                            </div>

                            {/* Icon on the right */}
                            {/* <div>
                                <BsBoxArrowDown size={30} className="text-danger" onClick={handleDownload} />
                            </div> */}
                        </div>
                        <hr className="bg-danger border-2 border-top border-danger" />
                        <div id="pdf-content" className="container">
                            {(props.auditData && props.auditData).map((audit: any) => {
                                // console.log(audit)
                                return (
                                    (audit.id === props.data.auditId && (
                                        <div>
                                            <div className="row" key={audit.id}>
                                                <div className="col-4">
                                                    <p>Organization Name: {audit.organizationName}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>Dependendent Organization: {audit.dependentOrgCode}</p>
                                                </div>
                                                <div className="col-4">
                                                </div>
                                                <hr className="bg-danger border-2 border-top border-danger" />
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p>Audit Name: {audit.name}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>Audit Type: {audit.auditType}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>Audit Code: {audit.auditCode}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="d-flex">Scope Of Audit: <div dangerouslySetInnerHTML={{ __html: audit.scopeOfAudit }} /></div>
                                                </div>
                                                <div className="col-4">
                                                    <p>Auditee Name: {audit.auditeeName}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>Auditor Name: {audit.auditorName}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p>Auditor Company Name :{audit.auditorCompanyName}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>Auditor Phone: {audit.auditorPhone}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>Auditor Email: {audit.auditorEmail}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p>Evidence Url: {audit.evidenceUrl}</p>
                                                </div>
                                                <div className="col-4">
                                                    <div className="d-flex">Overall Audit Remark: <div dangerouslySetInnerHTML={{ __html: audit.overallRemark }} /></div>
                                                </div>
                                                <div className="col-4">
                                                    <p>Overall Audit Status: {audit.overallStatus}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p>Validity Period in days: {audit.validityPeriodInDays}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>Next Audit recommendation in days: {audit.nextAuditRecommendation}</p>
                                                </div>
                                                <div className="col-4">

                                                </div>
                                                <hr className="bg-danger border-2 border-top border-danger" />
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p>Audit Framework: {audit.auditFrameworkName}</p>
                                                </div>
                                                <div className="col-4">
                                                </div>
                                                <div className="col-4">
                                                </div>

                                            </div>
                                            <div>
                                                <Table
                                                    // scroll={isGeneratingPDF ? { x: "" } : { x: "max-content" }} // Disable scrolling while generating PDF
                                                    dataSource={dataSource}
                                                    columns={TABLE_COLUMN_REPORT_DETAILS}
                                                    pagination={false}
                                                // rowKey={(record) => `${record.dimensionId}_${record.processId}`}
                                                />

                                            </div>
                                        </div>

                                    ))
                                )
                            })}
                            {/* <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-block mt-3 mx-2 btn-width"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                            </div> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal >

            <Modal
                aria-labelledby="forgotPassword-modal"
                show={openEmailModal}
                onHide={handleEmailClose}
                className="login"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4 className="text-align-center fw-bold text-cm-primary1">Send Email</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {sendAuditDocumentResponse.isLoading ? (
                        <Loading />
                    ) : (
                        ""
                    )}
                    <Formik
                        initialValues={{
                            email: "",
                            subject: "",
                            body: "",
                            type: "",
                        }}
                        onSubmit={handleSubmitForm}
                    // validationSchema={}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className="d-flex flex-column rounded-3 overflow-hidden p-md-4 px-3 pt-md-0 pt-0 pb-2">
                                    <div className="mb-3">
                                        <div className="d-flex">
                                            <div>Email</div>
                                            <div className="text-danger">*</div>
                                        </div>
                                        <input
                                            type="email"
                                            value={values.email}
                                            name="email"
                                            onChange={handleChange}
                                            className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.email ? 'is-invalid' : ''}`}
                                            id="email"
                                            aria-describedby="email"
                                            placeholder="xyz@abc.com"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <div className="d-flex">
                                            <div>Subject</div>
                                        </div>
                                        <input
                                            type="text"
                                            value={values.subject}
                                            name="subject"
                                            onChange={handleChange}
                                            className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.subject ? 'is-invalid' : ''}`}
                                            id="subject"
                                            aria-describedby="subject"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <div className="d-flex">
                                            <div>Body</div>
                                        </div>
                                        <ReactQuill
                                            value={values.body}
                                            onChange={(content, delta, source, editor) => {
                                                setFieldValue('body', content);
                                            }}
                                        />
                                        {/* <textarea
                                            value={values.body}
                                            name="body"
                                            onChange={handleChange}
                                            className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.body ? 'is-invalid' : ''}`}
                                            id="body"
                                            aria-describedby="body"
                                        /> */}
                                    </div>
                                    <div className="w-100 mt-3">
                                        <div className="d-flex">
                                            <div>Document Type</div>
                                        </div>
                                        <div className="pe-3">
                                            <select
                                                className="form-select form-select-sm w-100"
                                                aria-label=".form-select-sm example"
                                                onChange={handleChange}
                                                name="type"
                                            >
                                                <option value="">Select Type</option>
                                                <option value={"pdf"}>PDF</option>
                                                <option value={"xlsx"}>Xlsx</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2">
                                        <button
                                            type="submit"
                                            className="btn btn-danger mt-3 mx-2 "
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal >
        </>
    );

};

export default ReportDetails