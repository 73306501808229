import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";


type Props = {
    data: any;
    organizationData: [];
};

const AuditStatusChart = (props: Props) => {

    const parentOrgs = props.organizationData.filter((org: any) => org.organizationType === "parent");

    const auditResults = parentOrgs.map((parentOrg: any) => {
        const dependentAudits = props.data.filter((audit: any) => audit.dependentOrgCode === parentOrg.name);

        return {
            parentOrgName: parentOrg.name,
            parentOrgCode: parentOrg.code,
            audits: dependentAudits.map((audit: any) => ({
                auditName: audit.name,
                overallStatus: audit.overallStatus
            }))
        };
    });

    console.log(parentOrgs, "parent")
    console.log(auditResults, "result nn")

    const statusMapping = [
        { id: 0, name: "Fully compliant" },
        { id: 1, name: "Major non compliant" },
        { id: 2, name: "Minor non compliant" },
        { id: 3, name: "Observations" },
        { id: 4, name: "Suggestions" },
        { id: 5, name: "Comment" },
        { id: 6, name: "Not applicable" },
        { id: 7, name: "On hold" },
    ];


    //   grouped bar 

    const categoriesGrouped = auditResults.map((org: any) => org.parentOrgName);

    const seriesGrouped = statusMapping.map((status: any) => ({
        name: status.name,
        data: auditResults.map((org: any) =>
            org.audits.filter((audit: any) => audit.overallStatus === status.name).length
        ),
    }));

    const optionsGrouped: any = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: false, // Ensure bars are grouped, not stacked
        },
        plotOptions: {
            bar: {
                horizontal: false, // Vertical bars
                columnWidth: '50%', // Adjust width of bars
            },
        },
        dataLabels: {
            enabled: false, // Disable data labels if not needed
        },
        xaxis: {
            categories: categoriesGrouped, // Organization names
            title: {
                text: 'Organizations',
            },
        },
        yaxis: {
            title: {
                text: 'Audit Count',
            },
        },
        legend: {
            position: 'top',
        },
        colors: ['#1E88E5', '#FDD835', '#D32F2F'], // Customize colors for statuses
        tooltip: {
            shared: true, // Show grouped tooltip
            intersect: false,
        },
    };

    return (
        <div>
            {/* <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={350}
            /> */}
            {/* <Chart options={options} series={series} type="bar" height={400} />; */}
            <Chart
                options={optionsGrouped}
                series={seriesGrouped}
                type="bar"
                height={350}
            />
        </div>
    );
};

export default AuditStatusChart;